
.bazis-chat__emoticons {
    position: absolute;
    z-index: 150;
    right: 48px;
    top: 40px;

    width: 342px; // 218
    border: 1px solid var(--bazis-border-color);
    border-radius: var(--bazis-border-radius);
    background: var(--bazis-background);
    padding: var(--bazis-padding-3x) var(--bazis-padding-4x);

    > div {
        display: flex;
        flex-wrap: wrap;
        gap: var(--bazis-margin-4x);
    }

    img {
        display: block;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    &::before {
        content: '';
        display: block;
        height: 15px;
        position: absolute;
        left: 0;
        right: 0;
        top: -15px;
    }

    .bazis-chat__control & {
        top: auto;
        right: 0;
        bottom: 45px;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05) inset, 0px 4px 24px -10px rgba(0, 0, 0, 0.10);

        &::before {
            display: none;
        }

        &::after {
            content: '';
            display: block;
            height: 15px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -15px;
        }

        .bazis-modal & {
            // top: -31px;
            right: 24px;
        }
    }

    .bazis-context-menu & {
        position: static;

        > div {
            max-height: 144px;
            overflow-y: auto;
            overflow-x: hidden;
            margin-right: calc(var(--bazis-margin-2x) * -1);
        }
    }
}