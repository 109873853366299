.bazis-chat__group-photo {
    --height-row: calc(400px / 12);
    display: grid;
    max-width: 364px;
    gap: var(--bazis-margin-2x);
    /* Будет создано 6 колонок */
    grid-template-columns: repeat(6, 1fr); // 54px
    /* Будет создано 6 рядов */
    grid-template-rows: repeat(6, var(--height-row));
    grid-auto-rows: var(--height-row);

    & + & {
        margin-top: var(--bazis-margin-2x);
    }
}

.bazis-chat__photo {
    // border-radius: var(--bazis-border-radius);
    // border: 1px solid var(--bazis-border-color);
    // background: var(--bazis-background);
    // width: var(--width-photo);
    width: 100%;
    height: 100%;
    text-decoration: none;

    grid-row: span 4;
    grid-column: span 3;

    &-1 {
        &:only-child,
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4),
        &:nth-last-child(5) {
            grid-row: span 6;
            grid-column: span 6;
        }

        &:nth-last-child(6) {
            grid-row: span 4;
            grid-column: span 3;
        }
    }
    &-2 {
        &:last-child {
            grid-row: span 6;
            grid-column: span 6;
        }

        &:nth-last-child(2) {
            grid-row: span 6;
            grid-column: span 3;
        }
        &:nth-last-child(3) {
            grid-row: span 2;
            grid-column: span 2;
        }
        &:nth-last-child(4) {
            grid-row: span 3;
            grid-column: span 3;
        }
        &:nth-last-child(5) {
            grid-row: span 4;
            grid-column: span 3;
        }
    }
    &-3 {
        &:last-child {
            grid-row: span 6;
            grid-column: span 3;
        }

        &:nth-last-child(2) {
            grid-row: span 2;
            grid-column: span 2;
        }
        &:nth-last-child(3) {
            grid-row: span 3;
            grid-column: span 3;
        }
        &:nth-last-child(4) {
            grid-row: span 4;
            grid-column: span 3;
        }
    }
    &-4 {
        &:last-child {
            grid-row: span 2;
            grid-column: span 2;
        }

        &:nth-last-child(2) {
            grid-row: span 3;
            grid-column: span 3;
        }
        &:nth-last-child(3) {
            grid-row: span 4;
            grid-column: span 3;
        }
    }
    &-5 {
        &:last-child {
            grid-row: span 3;
            grid-column: span 3;
        }

        &:nth-last-child(2) {
            grid-row: span 4;
            grid-column: span 3;
        }
    }
    &-6 {
        grid-row: span 4;
        grid-column: span 3;
    }

    .bazis-figure {
        // --width: calc(var(--width-photo) - 2px);
        // --height: calc(var(--width-photo) - 1px);
        --width: 100%;
        --height: 100%;
        --border-width: 1px;
        --border-radius: var(--bazis-border-radius);
        margin: 0;

        img {
            --border-radius: 0;
        }

        bazis-button {
            position: absolute;
            top: var(--bazis-margin-1x);
            right: var(--bazis-margin-1x);
        }
    }

    .bazis-figcaption {
        display: flex;
        padding: var(--bazis-padding-4x);
        gap: var(--bazis-margin-3x);
        align-items: center;
        text-decoration: none;
        color: var(--bazis-text-color);

        .bh-ellipsis {
            max-width: 154px;
        }
    }

    &__size {
        display: block;
        // padding-top: var(--bazis-padding-1x);
        font-size: 11px;
        line-height: 12px;
        color: var(--bazis-text-color-secondary);
    }

    bazis-badge {
        --height: 36px;
        --padding-start: var(--bazis-padding-1x);
        --padding-end: var(--bazis-padding-1x);
        --padding-top: 3px;
        --padding-bottom: 1px;
        --margin-start: 0;
        --margin-end: 0;
        --font-size: 12px;
        border-radius: 2px;
        float: left;
        width: 40px;
        line-height: 32px;
        text-transform: uppercase;

        &:last-of-type {
            display: none;
            align-items: center;
            border-radius: var(--bazis-border-radius);
        }
    }

    &:hover {
        bazis-badge {
            &:first-of-type {
                display: none;
            }
            &:last-of-type {
                display: block;
            }
        }
    }
}
