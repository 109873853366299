.bazis-control_autocomplete {
    &,
    .bazis-control__field-group__end button {
        cursor: text;
    }

    /* Hide the popper when the reference is hidden */
    [data-popper-reference-hidden] {
        visibility: hidden;
        pointer-events: none;
    }

    [data-popper-placement] .bazis-list_control-dd {
        position: static;
        display: block;
    }
}
