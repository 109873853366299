bazis-range {
    .sl-range_rate & {
        --bar-height: 4px;
        --thumb-width: 32px;
        --thumb-height: 32px;
        --thumb-box-shadow: none;
        --thumb-background: no-repeat 50% 50% / 16px 16px url('#{$path}/images/star.svg')
            var(--bazis-color-warning);
        --track-background: var(--bazis-background-dark);
        --font-size: 13px;
        --line-height: 16px;
        --color: var(--bazis-text-color-contrast);

        &::part(pin) {
            opacity: 0;
            background: no-repeat url('#{$path}/images/rate.png') var(--bazis-text-color);
            background-size: 144px;
            background-position: 12px 12px;
            padding: var(--bazis-padding-3x) var(--bazis-padding-3x) var(--bazis-padding-3x)
                calc(var(--bazis-padding-3x) + var(--bazis-padding-4x) + var(--bazis-padding-2x));
            border-radius: 4px;
            top: calc(0px - var(--thumb-width) - 2px - var(--bazis-padding-6x) - var(--bazis-padding-3x));
            transform: translateX(calc(12px - 50%));

            &::after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: calc(50% - 3px);
                border-left: 6px solid transparent;
                border-right: 6px  solid transparent;
                border-top:  4px solid var(--bazis-text-color);
            }
        }

        &::part(pin-rate_2) {
            background-position: -20px -20px;
        }
        &::part(pin-rate_3) {
            background-position: -52px -52px;
        }
        &::part(pin-rate_4) {
            background-position: -84px -84px;
        }
        &::part(pin-rate_5) {
            background-position: -116px -116px;
        }

        &:focus::part(pin){
            opacity: 1;
        }

        &::part(range) {
            margin: 0;
        }

        &::part(scale) {
            margin-top: var(--bazis-margin-4x);
            font-size: 12px;
            line-height: 16px;
            color: var(--bazis-text-color-secondary);
        }
    }
}
