.sl-menu_profile {
    @media (max-width: 1024px) {
        --box-shadow: 0px -15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);
        --background-list: var(--bazis-color-white);
        --padding-bottom-list: var(--bazis-padding-5x);
        --padding-top-list: var(--bazis-padding-3x);
        --border-style-list: solid;
        --border-width-list: 1px 0 0;
        --border-color-list: var(--bazis-border-color);

        --background-hover: transparent;
        --color-hover: var(--bazis-color-action);
        --line-height: 12px;

        position: fixed;
        z-index: 150;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        margin: 0;

        .bazis-item {
            --font-size: 10px;
            width: 100%;
            flex-direction: column;
            padding: 0;
            margin: 0;
            text-align: center;

            &.bazis-dropdown {
                --border-width: 0;
                --top: auto;
                --bottom: 60px;
                --padding-top: var(--bazis-padding-4x);
                --padding-bottom: var(--bazis-padding-4x);
                --padding-start: var(--bazis-padding-3x);
                --padding-end: var(--bazis-padding-3x);
                --width: 112px;
                cursor: pointer;

                &:hover {
                    color: var(--bazis-text-color);
                }

                .bazis-dropdown__trigger {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                }

                .bazis-item {
                    color: var(--bazis-text-color);
                    margin-bottom: var(--bazis-margin-4x);

                    &:hover,
                    &.active {
                        color: var(--color-hover);
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        bazis-icon {
            margin: 0 0 var(--bazis-margin-1x);
            padding: 0;
            background: transparent;
            border-radius: 0;
        }
    }
}

.sl-menu_news {
    --padding-top: var(--bazis-padding-2x);
    --padding-bottom: var(--bazis-padding-2x);
    --margin-bottom: 0;
    --background-hover: transparent;

    --margin-start-list: calc(var(--bazis-margin-6x) * -1);
    --margin-end-list: calc(var(--bazis-margin-6x) * -1);

    .bazis-item {
        --font-size: 13px;
        --font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        text-align: left;
        gap: var(--bazis-margin-3x);

        &--selected {
            --font-weight: 500;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 2px;
                background: var(--bazis-text-color);
            }
        }
    }

    .bazis-figure {
        --width: 32px;
        --height: 32px;
        --border-radius: 50%;
        --border-width: 0;
        margin: 0;
    }
}
