.bazis-control {
    --height: 34px;

    --margin-bottom: var(--bazis-margin-6x);

    --padding-field-start: var(--bazis-padding-3x);
    --padding-field-end: var(--bazis-padding-3x);

    --background: var(--bazis-color-white);
    --border-radius: var(--bazis-border-radius);
    --border-width: 1px;
    --border-color: var(--bazis-border-color-dark);
    --border-color-hover: var(--bazis-text-color-secondary);
    --border-color-focus: var(--bazis-color-primary);
    --border-color-active: var(--bazis-color-primary);
    --border-style: solid;
    --border-style-estimated: dashed;
    --color: var(--bazis-text-color);
    --font-size: 13px;

    --size-icon: 16px;

    --background-disabled: var(--bazis-background-medium);

    .bazis-datetime-control &,
    .sl-card-mobile__selection & {
        --margin-bottom: 0;
    }

    &__units {
        color: var(--bazis-text-color-secondary);
    }

    &__field-group {
        &-wrapper {
            .bazis-control__field-group {
                margin-right: var(--bazis-margin-8x);
                width: calc(100% - var(--bazis-margin-8x));
            }

            bazis-button {
                --border-radius: 50%;
                margin-left: var(--bazis-margin-2x);
            }
        }

        > button {
            --size-icon: 12px;
        }

        // &__start,
        // &__end {
        // }

        &__end {
            button {
                min-width: calc(var(--height) + var(--bazis-padding-2x));
            }

            bazis-button.bazis-btn_clear.bazis-eraser {
                --padding-end: var(--bazis-padding-2x);
                --padding-start: var(--bazis-padding-2x);
            }
        }
    }

    textarea {
        --padding-field-top: 10px;
        --padding-field-bottom: 10px;
        min-height: 64px;
    }

    bazis-button.bazis-btn_clear:not(
            bazis-button-date .bazis-control__field-group__start bazis-button.bazis-btn_clear
        ) {
        --height: 34px;
        --background-hover: transparent;
        --background-focused: transparent;
        --background-activated: transparent;
        --color-hover: var(--bazis-color-action);
        --color-focused: var(--bazis-color-action);
        --color-activated: var(--bazis-color-action);
    }

    bazis-button,
    .bazis-figure_btn {
        input[type='file'] {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
        }
    }

    .bazis-figure {
        --height: 134px;
    }

    .bazis-list_control-dd {
        --position-top: var(--height);
    }

    @media (max-width: 576px) {
        bazis-input-timeinterval bazis-col:last-child & {
            --margin-top: var(--bazis-margin-6x);
        }
    }
}

.dt-desktop {
    .bazis-input-time {
        .bazis-control__field-group__end bazis-button:not(.bazis-eraser) {
            display: none;
        }
    }
}

.bazis-map-point-control {
    .bazis-control {
        --margin-bottom: 0;
    }

    @media (max-width: 576px) {
        bazis-col:first-child & {
            --margin-bottom: var(--bazis-margin-6x);
        }
    }
}
