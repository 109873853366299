.bazis-select {
    --padding-start: var(--bazis-padding-3x);
    --padding-end: var(--bazis-padding-3x);
    --border-radius: var(--bazis-border-radius);
    --background: var(--bazis-color-white);
    --background-hover: var(--bazis-color-white);
    --background-focus: var(--bazis-color-white);

    &_secondary {
        --background: var(--bazis-color-info-tint);
        --background-hover: var(--bazis-color-info-tint);
        --background-focus: var(--bazis-color-info-tint);
        --background-active: var(--bazis-color-info-tint);
        --padding-end: 0;
        color: var(--color);

        .bazis-select__trigger {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: var(--border-radius);

            bazis-icon {
                --size-icon: 20px;
                flex-shrink: 0;
                color: var(--color);

                &:first-child {
                    margin-right: var(--bazis-margin-1x);
                }
            }
        }
    }

    bazis-label.bazis-label_inner + .bazis-control__field & {
        --padding-start: var(--bazis-padding-1x);
    }

    &__trigger + .bazis-control__field--focused {
        &.bazis-control__field_opacity {
            opacity: 0.5;
        }
    }
}

.bazis-select-text {
    --margin-top: var(--bazis-margin-1x);
    --margin-bottom: var(--bazis-margin-1x);

    &__label {
        --font-size: 14px;
        --line-height: 20px;
        margin-right: var(--bazis-margin-2x);

        &.bh-font-medium {
            --font-weight: 500;
        }
    }

    .bazis-list_control-dd {
        top: 16px;
        left: 0;
        width: auto;
        min-width: 140px;

        .bazis-checkbox {
            min-width: 160px;

            &__label {
                margin-right: 0;
            }
        }
    }
}

bazis-select-native select {
    margin-left: 0;
}
