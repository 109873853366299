.sl-main-page {
    --header-height: 56px;
    --bazis-background-medium: #f7f7f7;
    background-color: var(--bazis-background-medium);

    h1 {
        --bazis-font-size-h1: 56px;
        --bazis-line-height-h1: 64px;
    }

    h2 {
        --bazis-font-size-h2: 48px;
        --bazis-line-height-h2: 56px;
        font-weight: 700;
    }

    .bazis-container {
        // position: relative;
        // background: var(--bazis-background-medium);

        @media (min-width: 1279.98px) {
            --max-width-container: 1224px;
        }

        @media (min-width: 1359.98px) {
            --max-width-container: 1224px;
        }

        @media (min-width: 1436px) {
            --max-width-container: 1224px;
        }
    }

    .bazis-slider {
        position: relative;
        margin-bottom: var(--bazis-margin-8x);

        &__indicator {
            margin-top: var(--bazis-margin-8x);
        }

        &__content {
            gap: var(--bazis-margin-6x);
        }

        &__controls {
            margin: 0;

            bazis-button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;

                &.bazis-slider__prev {
                    left: -68px;
                }
                &.bazis-slider__next {
                    right: -68px;
                }
            }
        }

        .bazis-block {
            --background: var(--bazis-background);
            --padding-top: var(--bazis-padding-2x);
            --padding-bottom: var(--bazis-padding-4x);
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
            --border-radius: 12px;
            --margin-bottom: 0;
            --width: calc((100% - var(--bazis-margin-6x) * 3) / 4);
            position: relative;
            cursor: pointer;
        }

        .bazis-figure {
            --height: 176px;
            --width: calc(100% + var(--bazis-margin-4x));
            // --min-width: 272px;
            --border-width: 0;
            margin: 0 calc(var(--bazis-margin-2x) * -1) var(--bazis-margin-5x);
            cursor: pointer;
        }

        h5 {
            --bazis-font-size-h5: 16px;
            --bazis-line-height-h5: 24px;
            font-weight: 500;
        }

        .bazis-list_between {
            --margin-top-list: var(--bazis-margin-3x);

            > .bazis-item {
                --margin-top: var(--bazis-margin-2x);
                --margin-bottom: 0;
                gap: var(--bazis-margin-4x);

                > span:last-child {
                    text-align: right;
                }
            }
        }

        @media (max-width: 1360px) {
            &__controls {
                bazis-button {
                    top: auto;
                    bottom: -76px;
                    transform: translateY(0);

                    &.bazis-slider__prev {
                        left: 0;
                    }
                    &.bazis-slider__next {
                        right: 0;
                    }
                }
            }
        }

        @media (max-width: 1024px) {
            .bazis-block {
                --width: calc((100% - var(--bazis-margin-6x)) / 2);
            }
        }

        @media (max-width: 576px) {
            .bazis-block {
                --width: 100%;
            }
        }
    }

    // @media (hover: none) and (pointer: coarse) {
    //     .bazis-slider {
    //         &__controls {
    //             display: none;
    //         }
    //         &__indicator {
    //             display: flex;
    //         }
    //     }
    // }

    // @media (hover: hover) and (pointer: fine) {
    //     .bazis-slider {
    //         &__controls {
    //             display: block;
    //         }
    //         &__indicator {
    //             display: none;
    //         }
    //     }
    // }

    .sl-section {
        --margin-top: 0;

        &__content {
            --padding-top: 120px;
            --padding-bottom: 0;

            @media (max-width: 1024px) {
                --padding-start: 0;
                --padding-end: 0;
            }

            @media (max-width: 576px) {
                --padding-top: 72px;
                // --padding-bottom: 64px;

                h2 {
                    --bazis-font-size-h2: 24px;
                    --bazis-line-height-h2: 32px;
                    --bazis-h2-margin: 0 0 var(--bazis-margin-4x);
                }

                // bazis-button.bazis-btn_large {
                //     display: block;
                //     max-width: 100%;
                // }
            }
        }

        // 1 section
        &_banner {
            .sl-section__content {
                --padding-top: 80px;
                text-align: center;
            }

            .bh-line-elements {
                --gap: var(--bazis-margin-6x);
            }

            h1 {
                --bazis-h1-margin: 0 0 var(--bazis-margin-6x);
                font-weight: 500;
                padding-bottom: 0;

                + p {
                    --bazis-font-size-p: 18px;
                    --bazis-line-height-p: 24px;
                    --bazis-p-margin: 0 0 40px;
                }
            }

            bazis-button {
                --height-large: 52px;
                font-size: 16px;
            }

            .bazis-figure {
                --width: 100%;
                --height: 440px;
                --border-width: 0;
                --border-radius: 12px;
                margin: 64px 0 0;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 238px;
                    height: 238px;
                    background: url('/assets/images/home-calc-curve.svg');
                }
            }

            @media (max-width: 1024px) {
                h1 {
                    --bazis-font-size-h1: 45px;
                    --bazis-line-height-h1: 52px;
                }
            }

            @media (max-width: 576px) {
                .sl-banner__content {
                    width: 100%;
                }
                h1 {
                    --bazis-font-size-h1: 24px;
                    --bazis-line-height-h1: 32px;
                    --bazis-h1-margin: 0 0 var(--bazis-margin-4x);
                    font-weight: 700;

                    + p {
                        --bazis-p-margin: 0 0 var(--bazis-margin-8x);
                    }
                }
                .bh-line-elements {
                    --gap: var(--bazis-margin-4x);
                    flex-direction: column;
                }
                .bazis-figure {
                    --height: 176px;
                    margin: 40px 0 0;

                    &::after {
                        display: none;
                    }
                }
            }
        }

        // 2 section
        &_steps {
            h2 {
                --bazis-h2-margin: 0 0 var(--bazis-margin-6x);

                + p {
                    --bazis-font-size-p: 18px;
                    --bazis-line-height-p: 24px;
                    --bazis-p-margin: 0 0 48px;
                }
            }

            .bh-line-elements {
                --gap: var(--bazis-margin-6x);
            }

            .bazis-block {
                --background: var(--bazis-background);
                --padding-top: var(--bazis-padding-4x);
                --padding-bottom: var(--bazis-padding-4x);
                --padding-start: var(--bazis-padding-4x);
                --padding-end: var(--bazis-padding-4x);
                --border-radius: 12px;
                --margin-bottom: 0;
                --width: calc((100% - var(--bazis-margin-6x) * 3) / 4);
            }

            bazis-badge {
                --height-large: 20px;
                --margin-start: 0;
                --margin-end: 0;
                --font-size-large: 12px;
                letter-spacing: 1px;
                text-transform: uppercase;

                &.bazis-color-primary {
                    background: rgba(var(--bazis-color-primary-rgb), 0.1);
                    color: var(--bazis-color-primary);
                }
            }

            h5 {
                margin: var(--bazis-margin-4x) 0 var(--bazis-margin-2x);
                font-weight: 500;
            }

            bazis-button {
                margin-top: var(--bazis-margin-1x);
            }

            .bazis-h6 {
                font-weight: 400;
            }

            @media (max-width: 768px) {
                .bh-line-elements {
                    flex-wrap: wrap;
                }
                .bazis-block {
                    --width: calc((100% - var(--bazis-margin-6x)) / 2);
                }
            }

            @media (max-width: 576px) {
                h2 + p {
                    --bazis-p-margin: 0 0 var(--bazis-margin-8x);
                }
                .bh-line-elements {
                    flex-direction: column;
                }
                .bazis-block {
                    --width: 100%;
                }
                bazis-button {
                    display: block;
                }
            }
        }

        // 3 section
        &_agrooperations {
            .sl-section__content {
                --padding-top: 120px;
            }

            h2 {
                --bazis-h2-margin: 0 0 calc(var(--bazis-margin-6x) + var(--bazis-margin-3x));

                // + p {
                //     --bazis-font-size-p: 18px;
                //     --bazis-line-height-p: 24px;
                // }
            }

            bazis-grid > bazis-row {
                > bazis-col {
                    --bazis-grid-column-padding-start-md: var(--bazis-padding-3x);
                    --bazis-grid-column-padding-end-md: var(--bazis-padding-3x);
                    --bazis-grid-column-padding-top-md: var(--bazis-padding-3x);
                    --bazis-grid-column-padding-bottom-md: var(--bazis-padding-3x);
                    --bazis-grid-column-padding-start-xs: 0;
                    --bazis-grid-column-padding-end-xs: 0;
                    --bazis-grid-column-padding-top-xs: var(--bazis-padding-2x);
                    --bazis-grid-column-padding-bottom-xs: var(--bazis-padding-2x);

                    &:nth-child(2) {
                        --bazis-grid-column-padding-end-md: 0;
                        --bazis-grid-column-padding-end-xl: var(--bazis-padding-3x);
                    }
                    &:nth-child(3) {
                        --bazis-grid-column-padding-start-md: 0;
                        --bazis-grid-column-padding-start-xl: var(--bazis-padding-3x);
                    }

                    &:first-child {
                        --bazis-grid-column-padding-start-md: 0;
                        --bazis-grid-column-padding-start-xl: 0;
                    }
                    &:last-child {
                        --bazis-grid-column-padding-end-md: 0;
                        --bazis-grid-column-padding-end-xl: 0;
                    }
                }

                &:last-child > bazis-col {
                    --bazis-grid-column-padding-bottom-md: 0;
                }
            }

            .sl-home-card-agro {
                position: relative;
                height: 176px;
                padding: 92px var(--bazis-padding-4x) var(--bazis-padding-4x);
                border-radius: 12px;
                // background-position: 50% 50%;
                // background-repeat: no-repeat;
                // background-size: cover;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    border-radius: 12px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                    background: linear-gradient(
                        0deg,
                        rgba(0, 64, 100, 0.5) 0%,
                        rgba(0, 64, 100, 0.5) 100%
                    );
                }

                &::after {
                    content: '';
                    position: absolute;
                    z-index: 2;
                    right: 0;
                    top: 0;
                    width: 88px;
                    height: 88px;
                    background: radial-gradient(
                        circle at top right,
                        rgba(var(--bazis-color-white-rgb), 0.5) 62px,
                        rgba(var(--bazis-color-white-rgb), 0.2) 63px,
                        rgba(var(--bazis-color-white-rgb), 0.2) 88px
                    );
                    border-radius: 0 12px 0 88px;
                }

                p {
                    position: relative;
                    z-index: 2;
                    --bazis-font-size-p: 14px;
                    --bazis-line-height-p: 20px;
                    --bazis-p-margin: 0 0 var(--bazis-margin-3x);
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    color: var(--bazis-text-color-contrast);
                }

                bazis-button {
                    position: relative;
                    z-index: 2;
                }

                .bazis-btn_clear {
                    --background: rgba(var(--bazis-color-white-rgb), 0.3);
                    --background-hover: rgba(var(--bazis-color-white-rgb), 0.5);
                    --background-focused: rgba(var(--bazis-color-white-rgb), 0.5);
                    --background-activated: rgba(var(--bazis-color-white-rgb), 0.5);
                    --color: var(--bazis-text-color-contrast);
                    // --color-hover: var(--bazis-text-color-secondary);
                    // --color-focused: var(--bazis-text-color-secondary);
                    // --color-activated: var(--bazis-text-color-secondary);
                }
            }

            @media (max-width: 768px) {
                bazis-grid {
                    display: flex;
                    gap: 32px;

                    bazis-row {
                        flex-shrink: 0;
                        width: 100%;
                    }
                }

                bazis-toolbar {
                    margin-top: var(--bazis-margin-4x);

                    // bazis-button {
                    //     --height-large: 48px;
                    //     --border-color: var(--bazis-color-base);

                    //     bazis-icon[slot='icon-only'] {
                    //         font-size: 24px;
                    //         margin-left: -9px;
                    //         margin-right: -9px;
                    //     }
                    //     @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
                    //         bazis-icon[slot='icon-only'] {
                    //             margin-left: unset;
                    //             margin-right: unset;
                    //             -webkit-margin-start: -9px;
                    //             margin-inline-start: -9px;
                    //             -webkit-margin-end: -9px;
                    //             margin-inline-end: -9px;
                    //         }
                    //     }
                    // }
                }

                .sl-home-card-agro {
                    .bazis-color-primary {
                        width: calc(100% - var(--bazis-margin-4x) - 36px);
                    }
                }

                .sl-home-agrooperations-wrapper {
                    margin-left: calc(var(--bazis-margin-8x) * -1);
                    padding-left: var(--bazis-padding-8x);
                    transition: transform 300ms;
                }
            }
            @media (max-width: 576px) {
                bazis-grid {
                    gap: 20px;
                }
                .sl-home-agrooperations-wrapper {
                    margin-left: calc(var(--bazis-margin-5x) * -1);
                    padding-left: var(--bazis-padding-5x);
                }
                bazis-tooltip {
                    width: 250px;
                }
            }
        }

        // 4 section
        &_calculator {
            h2 {
                --bazis-font-size-h2: 32px;
                --bazis-line-height-h2: 40px;
                --bazis-h2-margin: 0 0 var(--bazis-margin-8x);
                font-weight: 500;
            }

            bazis-select-native select {
                margin-left: 0;
            }

            .sl-section__content {
                .bazis-block {
                    --padding-top: 0;
                    --padding-bottom: 0;
                    --padding-start: 0;
                    --padding-end: 0;
                    --background: var(--bazis-color-white);
                    --border-radius: 12px;
                    position: relative;

                    &__content {
                        display: flex;
                        gap: 40px;
                    }
                }
            }

            .sl-form {
                padding: 40px 0 40px 40px;

                p:last-child {
                    margin-top: var(--bazis-margin-6x);
                }

                &__submit {
                    --height-large: 52px;
                    // max-width: 265px;
                    // width: 100%;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.5px;

                    &.bazis-btn--disabled {
                        bazis-icon {
                            transition-property: transform;
                            animation-name: rotate;
                            animation-duration: 1.2s;
                            animation-iteration-count: infinite;
                            animation-timing-function: linear;
                        }
                    }
                }

                &__footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0;
                    margin: var(--bazis-margin-2x) 0 0;
                    border: 0;
                    gap: 16px;
                    min-height: 0;
                }
            }

            .sl-result-price {
                flex-shrink: 0;
                text-align: right;
            }

            .sl-result {
                margin-bottom: var(--bazis-margin-2x);
                font-size: 20px;
                line-height: 24px;

                &_no {
                    text-align: right;
                }
            }

            bazis-interrogation {
                --margin-start: var(--bazis-margin-2x);
            }

            .bazis-figure {
                --width: 50%;
                --height: auto;
                --border-width: 0;
                --border-radius: 0 12px 12px 0;

                position: relative;
                margin: 0;
                flex-shrink: 0;
                min-height: 436px;
            }

            // @media (max-width: 1024px) {
            //     .sl-section__content {
            //         .bazis-block {
            //             --padding-start: 40px;
            //             --padding-end: 40px;
            //         }
            //     }
            //     h2 {
            //         --bazis-font-size-h2: 32px;
            //         --bazis-line-height-h2: 40px;
            //         text-align: center;
            //     }
            //     .sl-form p:last-child {
            //         margin-top: var(--bazis-margin-6x);
            //     }
            // }

            @media (max-width: 768px) {
                h2 br {
                    display: none;
                }

                .sl-section__content {
                    .bazis-block {
                        --padding-bottom: var(--bazis-padding-8x);

                        &__content {
                            flex-direction: column-reverse;
                            gap: var(--bazis-margin-8x);
                        }
                    }
                }
                .bazis-figure {
                    --width: 100%;
                    --height: 176px;
                    --border-radius: 12px 12px 0 0;
                    min-height: 0;
                }

                .sl-form {
                    padding: var(--bazis-padding-8x) var(--bazis-padding-5x) 0;

                    &__footer {
                        flex-direction: column-reverse;
                        align-items: flex-start;
                        gap: var(--bazis-margin-6x);
                    }
                }
                .sl-result-price {
                    text-align: left;
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    .bazis-block {
                        --padding-top: 0;
                        --padding-start: 0;
                        --padding-end: 0;
                    }
                }
                h2 {
                    --bazis-font-size-h2: 24px;
                    --bazis-line-height-h2: 32px;
                    --bazis-h2-margin: 0 0 var(--bazis-margin-8x);
                }
            }
        }

        // 6 section
        &_advantages {
            .bazis-block {
                --background: var(--bazis-background-dark);
                --padding-top: var(--bazis-padding-6x);
                --padding-bottom: var(--bazis-padding-6x);
                --padding-start: var(--bazis-padding-7x);
                --padding-end: var(--bazis-padding-7x);
                --border-radius: 12px;
                --margin-bottom: var(--bazis-margin-2x);

                &.bazis-color-primary {
                    --background: var(--bazis-color-base);
                    --color: var(--bazis-color-contrast);
                }
            }

            bazis-grid > bazis-row {
                > bazis-col {
                    --bazis-grid-column-padding-start-xl: var(--bazis-padding-3x);
                    --bazis-grid-column-padding-end-xl: var(--bazis-padding-3x);
                    --bazis-grid-column-padding-top-xl: 0;
                    --bazis-grid-column-padding-bottom-xl: var(--bazis-padding-6x);
                    // --bazis-grid-column-padding-start-xs: 0;
                    // --bazis-grid-column-padding-end-xs: 0;
                    // --bazis-grid-column-padding-top-xs: 0;
                    // --bazis-grid-column-padding-bottom-xs: var(--bazis-padding-6x);
                    // --bazis-grid-column-padding-start-lg: var(--bazis-padding-3x);

                    &:first-child {
                        --bazis-grid-column-padding-start-xl: 0;
                    }
                    &:last-child {
                        --bazis-grid-column-padding-end-xl: 0;
                    }
                }

                // &:last-child > bazis-col {
                //     --bazis-grid-column-padding-bottom-xl: 0;
                //     --bazis-grid-column-padding-bottom-xs: 0;
                // }
            }

            .bazis-p_major {
                --bazis-p-margin-major: 0 0 var(--bazis-margin-2x);
                padding-left: var(--bazis-padding-7x);
            }

            bazis-icon {
                float: left;
                margin-right: var(--bazis-margin-3x);
                margin-top: 2px;
            }

            .bazis-h6 {
                overflow: hidden;
                font-weight: 500;
            }

            &__banner.bazis-block {
                --margin-top: 152px;
                --margin-start: auto;
                --margin-end: auto;
                --max-width: 1016px;

                --padding-top: var(--bazis-padding-8x);
                --padding-bottom: var(--bazis-padding-8x);
                --padding-start: calc(var(--bazis-padding-3x) * 4);
                --padding-end: calc(var(--bazis-padding-3x) * 4);

                .bazis-figure {
                    --height: 260px;
                    --width: 397px;
                    --border-width: 0;
                    --border-radius: 0;
                    --background: transparent;
                    margin: -80px 0 0 var(--bazis-margin-5x);
                    float: left;
                }

                h4 {
                    margin-bottom: var(--bazis-margin-2x);
                }
                p {
                    --bazis-font-size-p: 16px;
                    --bazis-line-height-p: 24px;
                }
                bazis-button {
                    margin-top: var(--bazis-margin-4x);
                }
                bazis-badge {
                    margin: 0 var(--bazis-margin-2x) 0 0;
                }
                .bazis-list_h {
                    --font-size: 16px;
                    --line-height: 24px;
                    --color: rgba(var(--bazis-text-color-contrast-rgb), 0.8);

                    > .bazis-item {
                        --margin-end: var(--bazis-margin-4x);
                        white-space: nowrap;
                    }
                }
            }
            @media (max-width: 1024px) {
                &__banner.bazis-block {
                    .bazis-figure {
                        margin-top: -49px;
                    }
                }
            }

            @media (max-width: 768px) {
                &__banner.bazis-block {
                    .bazis-figure {
                        margin-left: -72px;
                        margin-top: -25px;
                    }
                }
            }

            @media (max-width: 576px) {
                h2 {
                    br {
                        display: none;
                    }
                }
                .bazis-block:not(.sl-section_advantages__banner) {
                    --margin-bottom: var(--bazis-margin-6x);
                    --margin-top: var(--bazis-margin-8x);
                }
                .bazis-h6 {
                    margin-bottom: var(--bazis-margin-4x);
                }
                &__banner.bazis-block {
                    --padding-start: var(--bazis-padding-5x);
                    --padding-end: var(--bazis-padding-5x);

                    .bazis-figure {
                        --height: 260px;
                        --width: 397px;
                        margin: -80px 0 var(--bazis-margin-8x) -38px;
                        float: none;
                    }

                    text-align: center;

                    bazis-button {
                        display: block;
                    }
                    .bazis-list_h {
                        --font-size: 16px;
                        --line-height: 24px;
                    }
                }
            }
        }

        // 8 section
        &_news {
            position: relative;

            .sl-section__content {
                --padding-bottom: 0;
            }

            .bazis-slider .bazis-block {
                --padding-bottom: calc(
                    var(--bazis-padding-4x) + var(--bazis-padding-5x) + var(--bazis-padding-5x)
                );

                bazis-link {
                    position: absolute;
                    bottom: var(--bazis-padding-4x);
                    left: var(--bazis-padding-4x);
                }
            }

            .bazis-list_short-info {
                --font-weight: 500;
                --margin-bottom-list: var(--bazis-margin-2x);
                flex-wrap: wrap;

                & > .bazis-item {
                    --font-size: 13px;

                    &::after {
                        width: 4px;
                        height: 4px;
                    }
                }
            }
        }

        // 9 section
        &_faq {
            position: relative;

            h2 {
                text-align: center;
            }

            .sl-accordion_landing {
                --open-close-icon-size: 24px;
                --open-close-color: var(--bazis-text-color-secondary);
                --padding-top-body: var(--bazis-padding-4x);

                &::part(header) {
                    font-size: 14px;
                    font-weight: 500;
                }
                &::part(body) {
                    line-height: 24px;
                }

                &.bazis-accordion--opened {
                    --padding-bottom-header: 0;
                    --color-header: var(--bazis-text-color);
                }
            }

            .sl-section__content {
                --padding-bottom: 120px;
                --padding-start: 0;
                max-width: 808px;
                margin: auto;
            }

            &__banner {
                --max-width: 1016px;
                --background: var(--bazis-text-color);
                --padding-top: var(--bazis-padding-8x);
                --padding-bottom: var(--bazis-padding-8x);
                --padding-start: 48px;
                --padding-end: 48px;
                --border-radius: 12px;
                --margin-bottom: 120px;
                --margin-start: auto;
                --margin-end: auto;
                --color: var(--bazis-text-color-contrast);

                display: flex;
                gap: calc(var(--bazis-margin-3x) * 4);

                p {
                    --bazis-font-size-p: 18px;
                    --bazis-line-height-p: 28px;
                    --bazis-p-margin: var(--bazis-margin-2x) 0 var(--bazis-margin-5x);
                    color: rgba(var(--bazis-text-color-contrast-rgb), 0.8);
                }

                .bazis-figure {
                    --width: 168px;
                    --height: 168px;
                    --border-width: 0;
                    --border-radius: 0;
                    --background: transparent;
                    margin: 0;
                    flex-shrink: 0;
                }
            }

            @media (max-width: 1024px) {
                background: transparent;

                &::before {
                    display: none;
                }

                .sl-section__content {
                    max-width: 100%;
                }

                h2 {
                    color: var(--bazis-text-color);
                }
                bazis-accordion + p {
                    --bazis-link-color: var(--bazis-color-action);
                    color: var(--bazis-text-color);
                }
            }

            @media (max-width: 576px) {
                &__banner {
                    --padding-start: var(--bazis-padding-5x);
                    --padding-end: var(--bazis-padding-5x);
                    flex-direction: column-reverse;
                    gap: var(--bazis-margin-8x);
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    p {
                        --bazis-font-size-p: 14px;
                        --bazis-line-height-p: 24px;
                    }

                    bazis-button {
                        display: block;
                    }
                }
            }
        }
        @media (max-width: 576px) {
            &_bids,
            &_news,
            &_vehicles {
                bazis-button.bazis-btn_large:not(
                        .bazis-slider__controls bazis-button.bazis-btn_large
                    ) {
                    width: calc(
                        100% - 44px * 2 - 24px * 2
                    ); // ширина кнопок управления и условный гап между ними
                }
            }
        }
    }

    app-home-executor {
        .sl-section_steps .bazis-block:first-child {
            --background: var(--bazis-text-color);
            --color: var(--bazis-text-color-contrast);
        }

        .sl-section_calculator {
            .sl-section__content .bazis-block {
                --background: var(--bazis-text-color);

                h2,
                .sl-result-price {
                    color: var(--bazis-text-color-contrast);
                }
            }

            .sl-form__footer {
                background: var(--bazis-text-color);
            }
        }

        .sl-section_advantages {
            .bazis-block:not(.bazis-color-primary) {
                --background: var(--bazis-text-color);
                --color: var(--bazis-text-color-contrast);
            }

            &__banner.bazis-block {
                --margin-top: 176px;
                --background: var(--bazis-text-color);
                --color: var(--bazis-text-color-contrast);

                .bazis-figure {
                    margin-top: -104px;

                    @media (max-width: 768px) {
                        margin-top: -73px;
                    }
                }
            }
        }
    }
}
