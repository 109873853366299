.bazis-chat__pin {
    // position: absolute;
    // z-index: 1;
    // top: 0;
    // left: 0;
    // right: 0;
    flex-shrink: 0;
    margin: calc(var(--bazis-margin-3x) * -1) calc(var(--bazis-margin-2x) * -1) 0 calc(var(--bazis-margin-4x) * -1);
    padding: 0 var(--bazis-padding-6x) var(--bazis-padding-2x);
    background: var(--bazis-color-white);
    border-bottom: 1px solid var(--bazis-border-color);
    height: 49px;

    display: flex;
    gap: var(--bazis-margin-4x);
    justify-content: space-between;

    .bazis-chat__title {
        color: var(--bazis-color-action);
    }

    > *:first-child {
        width: 100%;
    }

    &__cite {
        .bazis-figure:last-child {
            --width: 20px;
            --height: 20px;
            --background: rgba(var(--bazis-color-action-rgb), 0.5);
            margin: 0 var(--bazis-margin-2x) 0 0;
            float: left;
        }

        .bazis-chat__reply__text {
            display: flex;
            height: 20px;
        }
    }
}
