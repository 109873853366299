.sl-calendar-shedule {
    border: 1px solid var(--bazis-border-color);
    border-radius: var(--bazis-border-radius);
    width: 100%;

    &-wrapper {
        display: flex;
        align-items: stretch;
        gap: var(--bazis-margin-2x);
        margin-top: var(--bazis-margin-4x);

        .bazis-block {
            --width: 304px;
            --margin-bottom: 0;
            --padding-top: var(--bazis-padding-4x);
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
            --padding-bottom: var(--bazis-padding-6x);
            flex-shrink: 0;

            h6 {
                --bazis-font-size-h6: 14px;
                --bazis-line-height-h6: 20px;
                --bazis-h6-margin: 0;
            }
        }

        .sl-form {
            margin-top: var(--bazis-margin-5x);
        }

        @media screen and (max-width: 768px) {
            flex-wrap: wrap;

            .bazis-block {
                --width: 100%;
            }
        }
    }

    &-legend {
        --bazis-font-size: 14px;
        display: flex;
        gap: var(--bazis-margin-4x);
        margin-top: var(--bazis-margin-2x);
        padding: var(--bazis-padding-2x);
    }

    &__weekdays {
        display: flex;
        width: 100%;
        border-bottom: 1px solid var(--bazis-border-color);
    }
    &__weekday {
        width: calc(100% / 7);
        padding: var(--bazis-padding-2x);
        border-right: 1px solid var(--bazis-border-color);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: var(--bazis-text-color-secondary);
        text-transform: uppercase;

        &:last-child {
            border-right: 0;
        }
    }

    &__days {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: calc(100% - 33px);
    }
    &__day {
        width: calc(100% / 7);
        min-height: 75px;
        border-bottom: 1px solid var(--bazis-border-color);
        border-right: 1px solid var(--bazis-border-color);
        padding: var(--bazis-padding-2x);
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        // color: var(--bazis-text-color-secondary);
        text-transform: uppercase;

        &--disabled {
            pointer-events: none;
            color: var(--bazis-placeholder-color);
        }

        &--current {
            color: var(--bazis-color-primary);
        }

        .sl-day {
            --background-day: initial;
            --border-color-day: initial;
            --border-style-day: solid;
            height: 24px;
            margin: var(--bazis-margin-2x) calc(var(--bazis-margin-2x) * -1 - 1px) 0;
            border: 1px var(--border-style-day) var(--border-color-day);
            border-left-width: 0;
            border-right-width: 0;
            background: var(--background-day);

            &--start {
                margin-left: var(--bazis-margin-2x);
                border-radius: var(--bazis-border-radius) 0 0 var(--bazis-border-radius);
                border-left-width: 1px;
            }
            &--end {
                margin-right: var(--bazis-margin-2x);
                border-radius: 0 var(--bazis-border-radius) var(--bazis-border-radius) 0;
                border-right-width: 1px;
            }
            &--start.sl-day--end {
                border-radius: var(--bazis-border-radius);
            }

            &_reservation {
                --background-day: var(--bazis-color-success);
                --border-color-day: var(--bazis-color-success);

                &.sl-day_increment {
                    --background-day: rgba(var(--bazis-color-success-rgb), 0.2);
                }
            }
            &_waiting {
                --background-day: var(--bazis-color-warning);
                --border-color-day: var(--bazis-color-warning);

                &.sl-day_increment {
                    --background-day: rgba(var(--bazis-color-warning-rgb), 0.2);
                }
            }
            &_other {
                --background-day: var(--bazis-color-secondary);
                --border-color-day: var(--bazis-color-secondary);

                &.sl-day_increment {
                    --background-day: rgba(var(--bazis-color-secondary-rgb), 0.2);
                }
            }
            &_increment {
                --border-style-day: dashed;
            }
            &_decrement {
                --background-day: rgba(var(--bazis-color-danger-rgb), 0.2);
                --border-color-day: var(--bazis-color-danger);
                --border-style-day: dashed;
            }
        }

        &:nth-child(7n) {
            border-right: 0;

            .sl-day {
                margin-right: calc(var(--bazis-margin-2x) * -1);

                &--end {
                    margin-right: var(--bazis-margin-2x);
                }
            }
        }
        &:nth-last-child(-n + 7):nth-child(7n-6),
        &:nth-last-child(-n + 6):nth-child(7n-5),
        &:nth-last-child(-n + 5):nth-child(7n-4),
        &:nth-last-child(-n + 4):nth-child(7n-3),
        &:nth-last-child(-n + 3):nth-child(7n-2),
        &:nth-last-child(2):nth-child(7n-1),
        &:last-child {
            border-bottom: 0;
        }
    }

    @media (max-width: 576px) {
        &-legend {
            flex-direction: column;
        }
    }
}

.sl-event-grid {
    --bazis-grid-columns: 28;
    --day-min-width: 20px;
    margin-top: var(--bazis-margin-4x);
    overflow-x: auto;

    bazis-row {
        flex-wrap: nowrap;
        min-width: calc(var(--totalDays) * var(--day-min-width));
    }
    bazis-col {
        min-width: var(--day-min-width);
    }

    &__weekdays {
        border-top: 1px solid var(--bazis-border-color);
        border-left: 1px solid var(--bazis-border-color);

        &:first-child {
            border-top-left-radius: var(--bazis-border-radius);
            border-top-right-radius: var(--bazis-border-radius);

            bazis-col:last-child {
                border-top-right-radius: var(--bazis-border-radius);
            }
        }

        bazis-col {
            padding: var(--bazis-padding-1x);
            border-right: 1px solid var(--bazis-border-color);
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            color: var(--bazis-text-color-secondary);
        }

        &_number {
            @extend .sl-event-grid__weekdays;

            bazis-col {
                padding: var(--bazis-padding-2x) var(--bazis-padding-1x);
                color: var(--bazis-text-color);
            }

            .sl-day_current {
                color: var(--bazis-color-primary);
            }
            .sl-day_last {
                color: var(--bazis-text-color-secondary);
            }
        }
    }

    &__events {
        border-left: 1px solid var(--bazis-border-color);

        &:last-child {
            border-bottom: 1px solid var(--bazis-border-color);
            border-bottom-left-radius: var(--bazis-border-radius);
            border-bottom-right-radius: var(--bazis-border-radius);

            bazis-col {
                padding-bottom: var(--bazis-padding-4x);

                &:last-child {
                    border-bottom-right-radius: var(--bazis-border-radius);
                }
            }
        }

        bazis-col {
            border-right: 1px solid var(--bazis-border-color);
            padding: 0 0 var(--bazis-padding-2x);

            &:last-child .sl-event {
                width: auto;
            }
        }
    }

    .sl-event {
        height: 16px;
        width: calc(100% + 1px);

        &_start {
            border-top-left-radius: var(--bazis-border-radius);
            border-bottom-left-radius: var(--bazis-border-radius);
            margin-left: var(--bazis-margin-1x);
        }

        &_end {
            border-top-right-radius: var(--bazis-border-radius);
            border-bottom-right-radius: var(--bazis-border-radius);
            margin-right: var(--bazis-margin-1x);
            width: auto;
        }

        &--confirmed {
            background: var(--bazis-color-danger);

            &.sl-event--hover {
                background: var(--bazis-color-danger-shade);
            }
        }

        &--waiting {
            background: var(--bazis-color-warning);

            &.sl-event--hover {
                background: var(--bazis-color-warning-shade);
            }
        }

        &--other {
            background: var(--bazis-color-secondary);

            &.sl-event--hover {
                background: var(--bazis-color-secondary-shade);
            }
        }

        &--draft {
            background: var(--bazis-color-info);

            &.sl-event--hover {
                background: var(--bazis-color-info-shade);
            }
        }
    }

    + .bazis-block {
        --margin-top: var(--bazis-margin-4x);
        --margin-bottom: var(--bazis-margin-2x);
    }

    @media (max-width: 576px) {
        --day-min-width: 50px;
        margin-right: -20px;
        padding-right: 20px;
    }
}
