dl {
    &.bazis-dl_inline {
        .sl-section &:last-child {
            dd:last-of-type {
                --margin-bottom-dd: 0;
            }
        }

        app-profile-okveds & {
            --width-dt: 96px;
            --color-dt: var(--bazis-text-color);
            --margin-bottom-dt: var(--bazis-margin-2x);
            --margin-bottom-dd: var(--bazis-margin-2x);
        }

        .sl-block_long-card .bazis-block__content_main & {
            --margin-bottom-dt: var(--bazis-margin-2x);
            --margin-bottom-dd: var(--bazis-margin-2x);
        }
    }

    &.bazis-dl_dot-leaders {
        --width-dt: 260px;
        --color-dt: rgba(var(--bazis-text-color-rgb), 0.6);

        .sl-section-sign & {
            --width-dt: 35%;
            --margin-top-dl: var(--bazis-margin-3x);
            --padding-start-dd: var(--bazis-margin-3x);

            dd {
                font-size: 14px;
            }
        }
    }

    &.bazis-dl_easy {
        --margin-bottom-dt: var(--bazis-margin-2x);
        --margin-bottom-dd: var(--bazis-margin-8x);

        dt {
            font-size: 13px;
        }
        dd:last-child {
            margin-bottom: 0;
        }

        .sl-card-mobile & {
            --margin-bottom-dd: var(--bazis-margin-4x);
            line-height: 16px;

            dt {
                font-weight: 500;
                line-height: 16px;
                color: var(--bazis-text-color-secondary);
            }

            + dl.bazis-dl_easy {
                --margin-top-dl: var(--bazis-margin-6x);
            }
        }

        app-organization-balance-widget & {
            --margin-top-dl: var(--bazis-margin-5x);
            --margin-bottom-dt: var(--bazis-margin-1x);
            --margin-bottom-dd: var(--bazis-margin-5x);

            dt {
                font-size: 12px;
                line-height: 16px;
            }

            dd {
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
            }
        }

        .sl-block_long-card .bazis-block__content_end & {
            --margin-bottom-dt: var(--bazis-margin-1x);
            --margin-bottom-dd: var(--bazis-margin-3x);

            dt {
                font-size: 11px;
                line-height: 12px;
                color: var(--bazis-text-color-secondary);
            }
        }
    }

    @media (min-width: 576.98px) {
        &.bazis-dl_inline {
            .sl-section &:last-child {
                dt:last-of-type {
                    --margin-bottom-dt: 0;
                }
            }

            dt:last-of-type {
                --margin-bottom-dt: 0;
            }
        }
        &.bazis-dl_dot-leaders {
            dt:last-of-type {
                --margin-bottom-dt: 0;
            }
        }
    }

    @media (max-width: 1024px) {
        h6 + &.bazis-dl_inline {
            --margin-top-dl: var(--bazis-margin-4x);
        }

        &.bazis-dl_inline {
            .sl-block_long-card .bazis-block__content_main & {
                dt,
                dd {
                    float: none;
                    clear: none;
                    width: 100%;
                }

                dd:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @media (max-width: 576px) {
        &.bazis-dl_inline {
            --margin-bottom-dt: var(--bazis-margin-2x);

            app-profile-okveds & {
                --margin-bottom-dt: var(--bazis-margin-1x);
                --margin-bottom-dd: var(--bazis-margin-3x);
            }
        }
        &.bazis-dl_dot-leaders {
            --padding-start-dd: 0;
            --color-dt: var(--bazis-text-color-secondary);

            dt::after {
                display: none;
            }
        }
        &.bazis-dl_dot-leaders_nowrap {
            dt {
                white-space: normal;

                &::after {
                    display: none;
                }
            }

            .sl-section-sign & {
                --padding-start-dd: 0;
            }
        }
        &.bazis-dl_easy {
            bazis-button {
                display: block;
            }
        }
    }
}
