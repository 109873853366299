.bazis-map {
    &__base-control {
        .bazis-btn-group_h {
            bazis-button {
                --border-color: var(--bazis-border-color-dark);

                --background: var(--bazis-color-white);
                --color: var(--bazis-text-color-secondary);

                --background-activated: var(--bazis-color-info-tint);
                --color-activated: vvar(--bazis-text-color-secondary);

                --background-hover: var(--bazis-background-medium);
                --color-hover: var(--bazis-text-color-secondary);

                --background-focused: var(--background-hover);
                --color-focused: var(--color-hover);

                &:hover {
                    &::part(native) {
                        border-color: var(--border-color);
                    }

                    bazis-icon {
                        color: var(--color-hover);
                    }
                }
            }
        }
    }
}
.bazis-map-wrapper {
    --height: 350px;

    .bazis-map-point-control & {
        margin-bottom: var(--bazis-margin-6x);

        &.bh-no-margin {
            margin-bottom: 0;
        }
    }
}
