.bazis-loader {
    --background: var(--bazis-color-white);
    --background-dark: var(--bazis-color-black);

    --min-width: 296px;
    --width: 100%;
    --max-width: 500px;
    --height: 150px;
    --font-size: 14px;
    --color: var(--bazis-text-color-secondary);
    --color-dark: var(--bazis-color-white);

    --spinner-size: 50px;
    --spinner-background: rgba(var(--bazis-color-secondary-rgb), 0.2);
    --spinner-color: var(--bazis-color-secondary);

    &__content {
        padding: var(--bazis-padding-3x);
    }

    &_dots {
        --dot-size: 2px;
        --dots-margin: var(--bazis-margin-1x);
        --color-rgb: var(--bazis-text-color-secondary-rgb);

        bazis-button.bazis-btn--disabled & {
            --color: var(--color-disabled);
            --color-rgb: var(--bazis-color-white-rgb);
        }
    }

    bazis-signing &:not(bazis-signing .bazis-modal__content &) {
        --height: 218px;
        top: auto;
        left: 8px;
        right: 8px;
        bottom: 8px;
        padding: var(--bazis-padding-8x) var(--bazis-padding-5x) 40px;
    }

    .bazis-control & {
        --background: transparent;
        --min-width: 20px;
        --width: 20px;
        --max-width: 20px;
        --height: 20px;

        --spinner-size: 20px;
        --spinner-background: transparent;
        --spinner-color: var(--bazis-color-primary);
        position: static;
        margin: auto;
        padding: 0;

        &__content {
            display: flex;
            padding: 0;
        }

        &__spinner {
            margin: 0;
        }
    }
}
