.bazis-vehicle-number {
    &:not(.bazis-vehicle-number--disabled) {
        --border-color: var(--bazis-text-color);
        --color: var(--bazis-text-color);
    }

    &_rus {
        .gnum-default & {
            .bazis-vehicle-number__part {
                &_0 {
                    --margin-start: var(--bazis-margin-1x);
                }
                &_1 {
                    --margin-end: var(--bazis-margin-1x);
                }
                &_0,
                &_1 {
                    --font-size: 22px;
                    --line-height: 17px;
                    letter-spacing: 3px;
                    width: auto;
                }
                &_2 {
                    --margin-end: 2px;
                    --margin-start: 3px;
                    --font-size: 11px;
                    --line-height: 17px;
                    position: relative;
                    width: 24px;
                    height: 100%;
                    letter-spacing: 1.5px;

                    &::before {
                        position: absolute;
                        top: 0;
                        left: -3px;
                        display: block;
                        content: '';
                        height: 22px;
                        width: 1px;
                        background: var(--color);
                    }

                    &::after {
                        position: absolute;
                        left: 0px;
                        right: 0px;
                        bottom: 2px;
                        content: 'RUS';
                        background: url('/assets/images/flag-ru.svg') no-repeat 100% 50%;
                        background-size: contain;
                        font-family: 'PT Sans Caption', sans-serif;
                        font-weight: 700;
                        font-size: 4.91px;
                        line-height: 6.35px;
                        letter-spacing: 0;
                        color: var(--bazis-color-action-tint);
                    }
                }

                @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                    &_0,
                    &_1 {
                        --line-height: 12px;
                    }
                }
            }
            &.bazis-vehicle-number--disabled {
                .bazis-vehicle-number__part_2 {
                    &::before {
                        background: var(--bazis-border-color);
                    }
                    &::after {
                        background-image: url('/assets/images/flag-ru-grey.svg');
                    }
                }
            }
        }

        .gnum-agro & {
            --height: 40px;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 72px;

            .bazis-vehicle-number__part {
                &_0 {
                    --font-size: 20px;
                    --line-height: 1.2;
                    --margin-start: 0;
                    --margin-end: 0;
                    width: 100%;
                    height: 20px;
                    flex-shrink: 0;
                    text-align: center;
                    letter-spacing: 2px;
                }
                &_1 {
                    --font-size: 19px;
                    --line-height: 17px;
                    --margin-end: 0;
                    position: relative;
                    width: 50%;
                    text-align: center;
                    letter-spacing: 2px;
                }
                &_2 {
                    --font-size: 16px;
                    --line-height: 1.5;
                    --margin-end: 0;
                    position: relative;
                    right: -1px;
                    border: 1px solid var(--border-color);
                    border-radius: var(--bazis-border-radius) 3px var(--bazis-border-radius) 3px;
                    padding: 0 3px;
                    width: 50%;
                    height: 19px;
                    text-align: center;
                    letter-spacing: 2px;

                    .os-Windows {
                        --line-height: 1;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: -1px;
                        right: 0;
                        left: 50%;
                        border-top: 1px solid var(--border-color);
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        top: 50%;
                        left: -1px;
                        border-left: 1px solid var(--border-color);
                    }
                }
            }
        }
    }
}
