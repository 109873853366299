.bazis-page {
    &-header {
        // only agro project (пока по макету)
        .bh-line-elements {
            --gap: 40px;
        }
    }

    &__tabs {
        padding-top: var(--bazis-padding-8x);
        margin-bottom: var(--bazis-margin-8x);
    }
}

.sl-page {
    &_edit {
        @extend .bazis-page;
    }

    &_detail {
        position: relative;
    }
}
