.bazis-grid-css {
    --gap: var(--bazis-margin-4x);
    --gap-row: var(--gap);
    --gap-col: var(--gap);
    --max-column-number: 6;
    --max-width: 1fr;
    --min-width: calc(
        (100% - var(--gap-col) * (var(--max-column-number) - 1)) / var(--max-column-number)
    );
    display: grid;
    gap: var(--gap-row) var(--gap-col);
    grid-template-columns: repeat(auto-fit, minmax(var(--min-width), var(--max-width)));
    // grid-auto-flow: row;

    &_analytics {
        --max-width: calc((100% - var(--gap-col) * 3) / 4);
        justify-content: center;
    }

    &_fr {
        --max-width: 1fr;
        justify-content: center;
    }
}
