th {
    .sl-table_mini & {
        --padding-top: 2px;
        --padding-bottom: 2px;
        --font-size: 10px;
        --line-height: 12px;
        text-transform: uppercase;
        min-width: 66px;

        &:last-child {
            --padding-end: var(--bazis-padding-2x);
            text-align: left;
        }
    }

    thead tr.sl-th_no-padding &,
    thead &.sl-th_no-padding {
        --padding-bottom: 0;
    }
    thead tr.sl-th_no-padding + tr & {
        --padding-top: 0;
    }
}

td {
    &.sl-table__cell-btns {
        --padding-top: var(--bazis-padding-2x);
    }

    .sl-tr-collapsed {
        // position: relative;
        // padding-right: var(--bazis-padding-4x);
        cursor: pointer;

        bazis-icon {
            // position: absolute;
            // right: 0;
            // top: 50%;
            // margin-top: calc(var(--bazis-margin-1x) * -1);
            font-size: 8px;
            color: var(--bazis-text-color);
        }
    }

    .sl-td-narrow & {
        --padding-top: var(--bazis-padding-1x);
        --padding-bottom: var(--bazis-padding-1x);
        position: relative;
        top: -10px;
    }
    .sl-td-narrow.bazis-td-no-border & {
        --padding-top: 0;
        --padding-bottom: var(--bazis-padding-1x);
    }

    .sl-tr--active & {
        --background: var(--bazis-background-accent);
    }
}

table {
    .sl-dropdown_action {
        bazis-button.bazis-dropdown__trigger {
            --color-hover: var(--bazis-color-action);

            bazis-icon {
                color: currentColor;
            }
        }

        &:hover {
            bazis-button.bazis-dropdown__trigger {
                --color: var(--color-hover);
            }
        }
    }

    .sl-tooltip_dropdown {
        --box-shadow: none;
        padding: 0;

        .bazis-list_control-dd {
            --margin-top-list: 0;
            position: static;
        }
    }
}

.sl-table_hoverable {
    tbody tr:hover td {
        background: var(--bazis-background-accent);
    }
}

.sl-table_clear {
    --margin-bottom: 0;

    td {
        // --padding-start: var(--bazis-padding-2x);
        --padding-top: var(--bazis-padding-2x);
        // --padding-end: var(--bazis-padding-2x);
        --padding-bottom: var(--bazis-padding-2x);
        --border-width: 0;

        &:first-child {
            --padding-start: 0;
        }
        &:last-child {
            --padding-end: 0;
        }
    }
}
