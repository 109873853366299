.bazis-block {
    --margin-bottom: var(--bazis-margin-6x);

    &__content {
        &_end {
            --width: 400px;
        }
    }

    &__footer {
        .bazis-block_accent & {
            > .bh-flex {
                margin-left: 56px;
            }
        }
    }

    .sl-block-total {
        float: right;
        border-top: 1px solid var(--bazis-border-color);
        padding-top: var(--bazis-padding-2x);
    }

    &_outline {
        .bazis-toolbar_baseline + & {
            --padding-top: var(--bazis-padding-8x);
        }

        & + .bazis-toolbar_baseline {
            --margin-top: calc(var(--bazis-margin-3x) * 3);
        }

        .sl-page_detail main & {
            --margin-bottom: 40px;
        }
    }

    &_accent {
        .bazis-block & {
            .sl-table_clear td {
                --padding-top: 2px;
                --padding-bottom: 2px;
            }
        }

        .sl-profile-page & {
            --padding-top: var(--bazis-padding-4x);
            --padding-bottom: var(--bazis-padding-4x);
            --padding-start: var(--bazis-padding-6x);
            --padding-end: var(--bazis-padding-6x);
            --margin-top: var(--bazis-margin-4x);
            --margin-bottom: var(--bazis-margin-4x);
        }

        .bazis-grid_form + hr {
            --margin-top: 0;
        }

        &.sl-block_large {
            --margin-bottom: 40px;
        }

        app-legal-document & {
            --margin-top: var(--bazis-margin-3x);
            --padding-top: var(--bazis-padding-4x);
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
            --padding-bottom: var(--bazis-padding-4x);

            a {
                font-weight: 500;
                letter-spacing: 0.25px;
                text-decoration: none;
            }
        }

        @media (max-width: 1024px) {
            main & {
                --padding-top: var(--bazis-padding-6x);
                --padding-start: var(--bazis-padding-5x);
                --padding-end: var(--bazis-padding-5x);
                --padding-bottom: var(--bazis-padding-6x);
            }
        }
    }

    &_on-map {
        --padding-top: var(--bazis-padding-3x);

        .sl-map-wrapper_medium & {
            --box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05) inset,
                0px 4px 24px -10px rgba(0, 0, 0, 0.1);
            max-height: calc(var(--height) - var(--bazis-margin-4x) * 2);
            overflow: hidden;
        }

        .sl-close {
            position: absolute;
            right: 16px;
            top: 16px;
        }
    }

    &_popup {
        --min-width: 344px;
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);
        --padding-top: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-6x);
    }

    &_search {
        --width: 100%;
        --padding-start: var(--bazis-padding-3x);
        --padding-end: var(--bazis-padding-3x);
        --padding-bottom: 0;

        .bazis-block__footer {
            --margin-top: var(--bazis-margin-6x);
            --margin-bottom: 0;
            --margin-start: calc(var(--bazis-margin-3x) * -1);
            --margin-end: calc(var(--bazis-margin-3x) * -1);
            --padding-top: var(--bazis-padding-3x);
            --padding-start: var(--bazis-padding-3x);
            --padding-end: var(--bazis-padding-3x);
        }

        app-edit-vehicle & {
            bottom: auto;
            top: 0;
            z-index: 160;
        }
    }

    aside & {
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);

        &.sl-block_small {
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
        }

        app-announcement-list & {
            --padding-top: var(--bazis-padding-6x);
            --padding-bottom: var(--bazis-padding-6x);
        }
    }

    .sl-page_detail aside.sl-right & {
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
    }

    aside.sl-aside_left & {
        --padding-top: var(--bazis-padding-4x);
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
        --padding-bottom: var(--bazis-padding-4x);

        @media (max-width: 992px) {
            &,
            &_outline {
                --padding-start: 0;
                --padding-end: 0;
                --padding-top: 0;
                --padding-bottom: 0;
                --border-width: 0;

                bazis-button {
                    display: inline-block;
                }
            }
        }

        @media (max-width: 576px) {
            &,
            &_outline {
                bazis-button {
                    display: block;
                }
            }
        }
    }

    @media (max-width: 576px) {
        &_on-map {
            --max-width: calc(100% - var(--bazis-padding-4x) * 2);
        }
    }
}

.sl-block {
    &_mini {
        --padding-top: var(--bazis-padding-2x);
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --padding-bottom: var(--bazis-padding-2x);
    }

    &_medium {
        --padding-top: var(--bazis-padding-5x);
        --padding-bottom: var(--bazis-padding-5x);
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
    }

    &_in-grid {
        --padding-top: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-bottom: 0;
    }

    &_faq {
        --padding-top: var(--bazis-padding-6x);
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-8x);
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
        --border-color-hover: var(--bazis-border-color-dark);

        &:last-child {
            --margin-bottom: 0;
        }
    }
    &_long-card {
        --padding-top: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-6x);
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);
        --margin-bottom: var(--bazis-margin-5x);
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
        --border-color-hover: var(--bazis-border-color-dark);
        --box-shadow: 0px 15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);
        --box-shadow-hover: 0px 10px 20px -10px rgba(144, 150, 162, 0.2);

        .bazis-block__title {
            align-items: center;
            margin-bottom: var(--bazis-margin-2x);
        }

        @media (max-width: 1024px) {
            --padding-top: var(--bazis-padding-3x);
            --padding-bottom: var(--bazis-padding-3x);
            --padding-start: var(--bazis-padding-3x);
            --padding-end: var(--bazis-padding-3x);

            .bazis-block__title {
                --gap: 10px;
                align-items: flex-start;
                flex-direction: column;
            }

            > .bh-flex {
                flex-wrap: wrap;
            }

            .bazis-block__content_main > .bh-flex {
                flex-direction: column;
                align-items: flex-start !important;
                gap: var(--bazis-margin-2x);
            }

            .bazis-block__content_end {
                --width: 100%;
                --border-width: 1px 0 0;
                --margin-start: 0;
                --padding-start: 0;
                --margin-top: var(--bazis-margin-2x);
                --padding-top: var(--bazis-padding-4x);
            }
        }
    }

    &_telemetry-statistic {
        --padding-top: var(--bazis-padding-5x);
        --padding-bottom: var(--bazis-padding-5x);
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
        --bazis-corner-top: var(--padding-top);
        --bazis-corner-right: var(--padding-end);

        position: relative;
        height: 320px;

        .highcharts-container {
            height: 248px !important;
        }
    }
}
