app-bid-summary {
    @media (max-width: 768px) {
        .bazis-map-circle {
            margin: 0 auto var(--bazis-margin-6x);
            width: 320px;
            height: 320px;

            .bazis-map {
                clip-path: circle(160px);
            }
        }
    }
    @media (max-width: 576px) {
        > bazis-toolbar {
            &:first-child {
                flex-direction: column-reverse;
                align-items: flex-start;

                &::part(end) {
                    margin: calc(var(--bazis-margin-3x) * 3) 0 var(--bazis-margin-3x);
                }

                bazis-interrogation {
                    --margin-start: 0;
                }
                h1 {
                    --bazis-h1-margin: 0 0 var(--bazis-margin-4x);
                }
            }
        }

        display: block;
    }
}
