.sl-star-rating_common {
    border-bottom: 1px dotted var(--bazis-color-warning);
    font-size: 16px;
    line-height: 24px;
    color: var(--bazis-color-warning);

    .sl-profile-page & {
        font-size: 45px;
        line-height: 52px;
    }
}
