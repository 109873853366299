app-proposition-edit {
    .bazis-datetime-control {
        --bazis-grid-columns: 8;
    }
}

app-proposition-view,
app-propositions-view {
    &:last-child {
        & > .bazis-block_accent {
            --margin-bottom: 0;
        }
    }

    @media (max-width: 1024px) {
        h5 {
            --bazis-font-size-h5: 18px;
            --bazis-line-height-h5: 24px;
        }
    }
}

app-propositions-view {
    h3 {
        --bazis-h3-margin: 40px 0 var(--bazis-margin-4x);

        + .sl-tabs-wrapper {
            margin: 0 0 var(--bazis-margin-5x);

            &::after {
                content: '';
                position: relative;
                top: -1px;
                z-index: -1;
                display: block;
                border-bottom: 1px solid var(--bazis-border-color);
            }
        }

        + .bazis-block_accent,
        + app-proposition-view .bazis-block_accent {
            --margin-top: var(--bazis-margin-6x);
        }
    }
}
