.bazis-stepper {
    .bazis-item {
        --margin-start: 0;
    }

    bazis-step {
        --color-muted: rgba(var(--bazis-color-white-rgb), 0.8);

        --sign-background: rgba(var(--bazis-color-white-rgb), 0.3);
        --sign-background-muted: rgba(var(--bazis-color-white-rgb), 0.3);
    }

    @media screen and (min-width: 920.98px) {
        --background-active: var(--bazis-stepper-background);
    }
}
