.bazis-chats {
    --height-header: 65px;
    --height-back: 68px;
    --width-left-col: 317px;
    --width-left-col-collapsed: 80px;
    --width-photo: 240px;

    display: flex;
    min-height: 518px;
    height: calc(100vh - 236px);
    border-radius: var(--bazis-border-radius);
    border: 1px solid var(--bazis-border-color-dark);
    background: var(--bazis-background);
    // min-width: 783px;

    &__search {
        height: var(--height-header);
        padding: 14px var(--bazis-padding-4x);
        border-bottom: 1px solid var(--bazis-border-color);
        border-radius: var(--bazis-border-radius) 0 0 0;

        .bazis-chats--collapsed & {
            padding: 0;

            bazis-button {
                --height: 100%;
                display: block;
            }
        }

        .bazis-control {
            --margin-bottom: 0;

            &__field {
                &-group__end .bazis-eraser {
                    visibility: visible;
                }
                // text-overflow: ellipsis;

                // &:hover {
                //     direction: rtl;
                //     text-align: right;
                //     text-overflow: ellipsis;
                // }

                // &:focus {
                //     direction: ltr;
                //     text-align: left;
                //     text-overflow: unset;
                // }
            }
        }
    }
    &__back {
        border-top: 1px solid var(--bazis-border-color);

        bazis-button {
            --height: 68px;
            --border-radius: 0;
            display: block;
        }
    }
}

bazis-chat,
bazis-chat-not-selected {
    width: calc(100% - var(--width-left-col));
    will-change: width;
    transition: width 200ms linear;

    .bazis-chats--collapsed & {
        width: calc(100% - var(--width-left-col-collapsed));
    }
}

bazis-chat-not-selected,
.bazis-chat__empty-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 14px;
    line-height: 20px;
    color: var(--bazis-text-color-secondary);
}

.bazis-chat-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.bazis-chat__main {
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(100% - var(--height-header));
    max-height: calc(100% - var(--height-header));

    .bazis-control {
        --margin-bottom: 0;
    }

    .bazis-loader_inline {
        z-index: 0;
    }
}

.bazis-chat__content {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: var(--bazis-padding-5x) var(--bazis-padding-2x) var(--bazis-padding-5x)
        var(--bazis-padding-4x);

    &__scroll {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        overflow: auto;
        padding-right: var(--bazis-padding-2x);

        &.bh-absolute {
            z-index: 100;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        &--opacity {
            opacity: 0;
        }
    }
}

.bazis-chat__footer {
    padding: var(--bazis-padding-4x) var(--bazis-padding-6x);
    border-top: 1px solid var(--bazis-border-color);
}

.bazis-chat__to-bottom {
    position: absolute;
    z-index: 100;
    right: var(--bazis-margin-5x);
    bottom: var(--bazis-margin-5x);
}

.bazis-chat__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.bazis-chat__date {
    font-size: 12px;
    line-height: 16px;
    color: var(--bazis-text-color-secondary);
    text-transform: capitalize;
}

.bazis-chat__text {
    font-size: 14px;
    line-height: 20px;
    word-wrap: break-word;
    white-space: pre-line;

    .bazis-list_chat & {
        font-size: 13px;
        line-height: 16px;
        color: var(--bazis-text-color-secondary);
    }

    img {
        &.bazis-chat__emoticon {
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 16px;
        }
    }

    &.bh-ellipsis {
        white-space: nowrap;

        br {
            content: '';
        }
        br:after {
            content: '\00a0';
        }
    }
}

.bazis-chat__files {
    display: flex;
    flex-direction: column;
    gap: var(--bazis-margin-3x); //4x

    bazis-file-inline {
        width: 392px;
    }

    &__item {
        display: flex;
        width: 100%;
        gap: var(--bazis-margin-4x);
        justify-content: space-between;
        align-items: center;

        > &:first-child {
            width: calc(100% - 1px);
        }

        bazis-button {
            flex-shrink: 0;
        }
    }

    bazis-sign-status {
        --size-sign: 36px;

        .bazis-loader_inline {
            --height: 28px;
            --min-width: 24px;
            --spinner-size: 24px;
            --spinner-color: var(--bazis-color-white);
            --spinner-background: var(--bazis-color-base);
            padding: 2px;
            margin: 4px;
            width: 28px;
            border-radius: 50%;

            .bazis-loader__spinner {
                margin: auto;
                padding: 4px;
            }
        }
    }
}

.bazis-context-menu bazis-context-menu-message {
    bazis-button {
        --height-xs: 20px;
        --padding-top: 0;
        --padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;

        &.bazis-btn_xs bazis-icon[slot='icon-only'] {
            font-size: 20px;
        }
    }
}

bazis-chat-send-message {
    & > .bh-flex-column {
        height: 100%;
    }
}

.bazis-chat__system-message {
    margin: var(--bazis-margin-3x) 0;
    text-align: center;
    font-weight: 500;
    color: rgba(var(--bazis-text-color-rgb), 0.6);

    &_new {
        background: var(--bazis-background-medium);
    }
}
