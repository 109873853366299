.bazis-btn-group {
    .bazis-btn-group__item > bazis-button.bazis-btn_solid {
        --background-activated: var(--bazis-color-primary);
        --background-hover: var(--bazis-color-primary-shade);
    }

    .bazis-btn-group__item > bazis-button.bazis-btn_clear {
        --color-activated: var(--bazis-color-primary);
        --color-hover: var(--bazis-color-primary);
    }
}
