.bazis-chat__search {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(var(--bazis-padding-3x) + 2px) var(--bazis-padding-6x);
    background: var(--bazis-color-white);
    border-bottom: 1px solid var(--bazis-border-color);

    bazis-toolbar::part(start),
    bazis-autocomplete {
        display: block;
        width: 100%;
    }

    .bazis-list_control-dd-wrapper,
    bazis-autocomplete > .bazis-control {
        position: static;
    }

    .bazis-control .bazis-list_control-dd {
        --margin-top-list: 0;
        --padding-end-list: var(--bazis-padding-2x);
        --padding-bottom-list: var(--bazis-padding-2x);
        --border-width-list: 0 0 1px 0;
        --background-list: var(--bazis-color-white);

        --position-top: 65px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: var(--bazis-padding-1x);
        --padding-bottom: var(--bazis-padding-1x);
        --border-width: 0 0 1px 0;
        --border-color: var(--bazis-border-color);
        --border-style: solid;
        --background: transparent;
        --background-hover: transparent;

        .bazis-item:last-child {
            --border-width: 0;
        }
    }

    bazis-infinite-list.bazis-list_control-dd .bazis-infinite-scroll-content {
        border-width: 0 0 1px 0;
        border-radius: 0;
        padding-top: var(--bazis-padding-2x);
        padding-bottom: var(--bazis-padding-2x);
        padding-left: var(--bazis-padding-6x);
        padding-right: var(--bazis-padding-4x);
    }

    .bazis-chat__message {
        margin: 0;
    }
}
