bazis-toggle {
    --background: var(--bazis-background);
    --background-checked: var(--bazis-color-action);
    --border-radius: 12px;
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-border-color-dark);
    --toggle-width: 29px;
    --toggle-height: 16px;

    --handle-background: var(--bazis-border-color-dark);
    --handle-background-checked: var(--bazis-background);
    --handle-border-radius: 5px;
    --handle-height: 10px;
    --handle-max-height: 10px;
    --handle-width: 10px;
    --handle-spacing: 3px;
    --handle-transition: transform 300ms, width 120ms ease-in-out 80ms, left 110ms ease-in-out 80ms,
        right 110ms ease-in-out 80ms;
    contain: strict;
    overflow: hidden;

    &::part(track) {
        transform: translate3d(0, 0, 0);
        transition: background-color 300ms;

        &::before {
            transform: scale3d(0, 0, 0);
        }
    }

    &::part(handle) {
        will-change: transform;
    }
}

.bazis-toggle--disabled {
    opacity: 0.5;
}
