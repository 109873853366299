.bazis-chat__header {
    --gap-header: var(--bazis-margin-5x);
    --width-header-controls: 128px;
    display: flex;
    justify-content: space-between;
    // display: grid;
    // grid-template-columns: 128px minmax(calc(100% - 128px * 2 - 20px * 2), 1fr) 128px; // 128 - под кнопки слева и справа
    // justify-content: space-between;
    align-items: center;
    gap: var(--gap-header);
    width: 100%;
    // position: relative;
    height: var(--height-header);
    padding: var(--bazis-padding-3x) var(--bazis-padding-6x);
    border-bottom: 1px solid var(--bazis-border-color);
    border-radius: 0 var(--bazis-border-radius) 0 0;

    > *:first-child {
        width: calc(100% - var(--width-header-controls) - var(--gap-header));
    }

    &_media {
        > *:not(.bazis-chat__title) {
            width: var(--width-header-controls);
        }

        > .bazis-chat__title {
            width: 100%;
            text-align: center;
        }
    }


    .bazis-chat__head {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__data {
        width: 100%;
        margin-top: var(--bazis-margin-1x);
        // text-align: center;
        line-height: 16px;

        bazis-link:not(.bazis-dropdown__trigger) {
            display: inline-block;
            overflow: hidden;
            max-width: calc(100% - 1px); // -75px
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
        }
    }

    // bazis-toolbar {
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     height: 100%;
    //     width: 100%;
    // }

    .bazis-dropdown {
        --top: 27px;
        --width: 260px;
        --right: auto;
        --left: 0;
        display: inline-block;
        text-align: left;

        &.sl-dropdown_action,
        &_action {
            --right: 0;
            --left: auto;
        }
    }
}
