.bazis-chat__media {
    position: absolute;
    top: var(--height-header);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 150;
    background: var(--bazis-background-medium);
    padding: var(--bazis-padding-6x) var(--bazis-padding-6x) var(--bazis-padding-8x);

    &__wrap {
        display: flex;
        flex-direction: column;
        background: var(--bazis-color-white);
        overflow: hidden;
        border-radius: var(--bazis-border-radius);
        max-height: 100%;
    }

    &__header {
        flex: 0 0 36px;
        padding: var(--bazis-padding-2x) var(--bazis-padding-6x) 0;
        border-bottom: 1px solid var(--bazis-border-color);

        bazis-tabs {
            --height: 36px;
        }

        bazis-tabs,
        .sl-profile-page & bazis-tabs {
            --padding-start: 0;
            --padding-end: 0;
            margin-top: 0;
        }
    }

    &__content {
        flex: 1 1 auto;
        min-height: calc(100% - 48px);
        overflow: auto;
        padding: var(--bazis-padding-8x) var(--bazis-padding-6x);
    }

    &__group {
        margin-top: var(--bazis-margin-4x);
        margin-bottom: var(--bazis-margin-6x);
    }

    .bazis-chat__group-photo {
        --height-row: 116px;
        max-width: 100%;
        max-width: 631px;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: var(--height-row);

        & + & {
            margin-top: var(--bazis-margin-2x);
        }
    }

    .bazis-chat__photo {
        grid-row: span 1 !important;
        grid-column: span 3;

        &-1 {
            &:only-child,
            &:nth-last-child(2) {
                grid-column: span 6;
            }
            &:nth-last-child(3) {
                grid-column: span 4;
            }
            &:nth-last-child(4) {
                grid-column: span 3;
            }
        }
        &-2 {
            &:last-child {
                grid-column: span 6;
            }
            &:nth-last-child(2) {
                grid-column: span 4;
            }
            &:nth-last-child(3) {
                grid-column: span 3;
            }
        }
        &-3 {
            &:last-child {
                grid-column: span 4;
            }

            &:nth-last-child(2) {
                grid-column: span 3;
            }
        }
        &-4 {
            &:last-child {
                grid-column: span 3;
            }
        }
    }

    .bazis-list {
        .bazis-item {
            &:first-child:not(.bazis-dropdown .bazis-list .bazis-item) {
                --padding-top: 0;
            }
            &:last-child .bazis-file-inline {
                margin-bottom: 0;
            }
        }

        &_between > .bazis-item {
            --margin-bottom: 0;
        }
    }
}
