.bazis-dropdown {
    --width: 362px;
    --padding-start: var(--bazis-padding-4x);
    --padding-end: var(--bazis-padding-4x);
    --padding-top: var(--bazis-padding-5x);
    --padding-bottom: var(--bazis-padding-5x);
    --background: var(--bazis-color-white);
    --border-radius: var(--bazis-border-radius);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-background-medium);
    --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
    --top: 48px;
    --right: 0;

    bazis-button.bazis-dropdown__trigger,
    &__trigger bazis-button,
    a.bazis-dropdown__trigger,
    &__trigger a {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;

        bazis-icon {
            --bazis-icon-s-size: 16px;
            --bazis-icon-l-size: 24px;

            &.bazis-icon-l {
                font-size: var(--bazis-icon-l-size);
            }
        }
    }

    .bazis-list_select {
        --margin-start-list: calc(var(--bazis-margin-4x) * -1);
        --margin-end-list: calc(var(--bazis-margin-4x) * -1);
        --margin-top-list: calc(var(--bazis-margin-5x) * -1);
        --margin-bottom-list: calc(var(--bazis-margin-5x) * -1);
        --border-radius: 0;
        --box-shadow: none;

        width: calc(100% - var(--margin-start-list) - var(--margin-end-list));
        border: 0;

        .bazis-item {
            --height: 36px;
            --color: var(--bazis-text-color);
            --color-hover: var(--bazis-text-color);
            margin: 0;
        }

        bazis-button.bazis-item {
            --background: transparent;
            --background-hover: var(--bazis-background-dark);
            --background-selected: var(--bazis-background-dark);
            --padding-start: var(--bazis-padding-3x);
            --padding-end: var(--bazis-padding-3x);
            --padding-top: var(--bazis-padding-2x);
            --padding-bottom: var(--bazis-padding-2x);
            --color-selected: var(--color);
            text-align: left;

            &::part(inner) {
                --padding-start: 0;
                --padding-end: 0;
                justify-content: flex-start;
            }

            &:active:hover {
                &::part(inner) {
                    background: var(--background-selected);
                    color: var(--color-selected);
                }
            }
        }
    }

    bazis-signing & {
        --top: 28px;
        --width: 210px;
        --color: var(--bazis-text-color-secondary);
        float: right;

        .bazis-list_select {
            border: var(--border-width-list) var(--border-style-list) var(--border-color-list);
        }
    }

    .bazis-list_vline & {
        --top: 28px;
        --width: 220px;
        margin-left: var(--bazis-margin-5x);
    }

    .bazis-btn-group .bazis-select-during-date-control .bazis-dropdown__body {
        --top: 40px;
    }
}
