bazis-chat.sl-chat_full {
    width: 100%;
}

.bazis-chat {
    &__header {
        bazis-toolbar {
            --padding-start: var(--bazis-padding-6x);
            --padding-end: var(--bazis-padding-6x);
        }
    }

    &__control .bazis-control__field-group__end {
        bazis-button.bazis-color {
            --bazis-color-shade: var(--bazis-color-primary-shade) !important;
            --bazis-color-tint: var(--bazis-color-primary-tint) !important;
            color: var(--bazis-color-primary);

            bazis-icon {
                color: var(--bazis-color-primary);
            }
        }
    }
}
