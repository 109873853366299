bazis-analytics-date-diagram {
    bazis-select {
        --control-width: 188px;
        float: right;
        position: relative;
        margin-top: calc(var(--bazis-margin-3x) * -3);
    }
}

.bazis-analytics-selected {
    display: flex;
    flex-wrap: wrap;
    align-items: top;
    gap: var(--bazis-margin-3x);
    margin-bottom: var(--bazis-margin-3x);
}
