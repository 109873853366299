.bazis-chat__modal-content {
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-right: var(--bazis-padding-6x);
}

bazis-chat-modal-add-users {
    --padding-end-content: var(--bazis-padding-6x);
    --padding-bottom-content: var(--bazis-padding-6x);
    --padding-start-footer: var(--bazis-padding-6x);
    --padding-end-footer: var(--bazis-padding-6x);
    --padding-top-footer: var(--bazis-padding-4x);
    --padding-bottom-footer: var(--bazis-padding-4x);

    .bazis-control {
        --margin-bottom: var(--bazis-margin-2x);
    }

    .bazis-list_control-dd {
        --padding-top-list: 0;
        --padding-bottom-list: 0;
        --border-width-list: 0;
        --box-shadow: 0;
        // --max-height-infinite-scroll-content:
        position: relative;
    }
}
