.bazis-vehicle-number-control {
    --width-group: 202px;

    &_rus {

        .bazis-control__field-group {
            height: 36px;
        }

        .bazis-vehicle-number-control__part {
            &_0 {
                --margin-start: var(--bazis-margin-3x);
                --margin-end: var(--bazis-margin-3x);
                --margin-bottom: 2px;
                --font-size: 26px;
                --line-height: 17px;
                width: 124px;

                input {
                    letter-spacing: 4px;
                }
            }
            &_1 {
                --margin-end: 7px;
                --margin-start: 7px;
                --margin-bottom: 2px;
                --font-size: 18px;
                --line-height: 20px;
                width: 39px;

                &::before {
                    content: '';
                    display: block;
                    width: 1px;
                    position: absolute;
                    top: 0;
                    bottom: -2px;
                    left: -5px;
                    background: var(--bazis-border-color-dark);
                }

                input {
                    letter-spacing: 2px;
                }
            }

            @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                &_0 {
                    --line-height: 12px;
                }
                &_1 {
                    --line-height: 20px;
                }
            }
        }
    }
}
