bazis-label {
    --margin-bottom: var(--bazis-margin-1x);
    --margin-bottom-major: var(--bazis-margin-5x);
    --line-height: 20px;
    --font-size: 14px;

    &.bazis-label_inner {
        --color: var(--bazis-text-color-secondary);
    }
}
