.bazis-control {
    .sl-form_oneline &,
    .sl-toolbar_table &,
    .sl-section .bazis-grid_form > bazis-row:last-child &,
    .bazis-block_search &,
    .sl-grid_table &,
    .sl-toolbar_table-header &,
    .sl-toolbar_notification &,
    .sl-row_with-error &,
    // .sl-main-page &,
    .sl-range-fields & {
        --margin-bottom: 0;
    }

    app-modal-report & {
        --margin-bottom: var(--bazis-margin-8x);
    }

    .sl-card-mobile-item &:not(.sl-card-mobile__selection .bazis-control) {
        --margin-bottom: var(--bazis-margin-4x);
    }

    .sl-control-empty-label
        &:not(
            .sl-grid_table .sl-control-empty-label .bazis-control,
            .sl-main-page .sl-section_calculator .bazis-control
        ) {
        --margin-top: var(--bazis-margin-6x);
    }

    .bazis-dl_inline dd & {
        --control-width: 220px;
    }

    @media (max-width: 992px) {
        &--activated {
            bazis-autocomplete &,
            app-search-filter & {
                --border-radius: 0;
                --border-width: 1px 0 1px 0;
                position: fixed;
                z-index: 1002;
                left: 0;
                right: 0;
                top: 0;

                .bazis-eraser {
                    display: none;
                }

                bazis-label {
                    display: none;
                }

                .bazis-list_control-dd {
                    --max-height: calc(100vh - 36px - 1px);
                    --margin-top-list: 1px;
                    --padding-top-list: 0;
                    --padding-end-list: var(--bazis-margin-5x);
                    --padding-start-list: var(--bazis-margin-5x);
                    --border-radius-list: 0;

                    --border-width: 0 0 1px 0;
                    --border-color: var(--bazis-border-color);
                    --border-style: solid;
                    --padding-end: 0;
                    --padding-start: 0;
                    --padding-top: var(--bazis-padding-6x);
                    --padding-bottom: var(--bazis-padding-5x);

                    height: var(--max-height);

                    .bazis-item {
                        --background-hover: var(--background);

                        &.bazis-radio,
                        &.bazis-checkbox {
                            border-bottom: 1px solid var(--border-color);
                            // margin-top: 0; // не обязательно?
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        app-proposition-edit-reservation .sl-row_with-error &,
        app-proposition-edit-reservation-mobile .sl-row_with-error & {
            --margin-bottom: var(--bazis-margin-6x);
        }
    }

    @media (max-width: 576px) {
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col &,
        .sl-row_with-error &,
        .sl-form .bazis-map-point-control bazis-col:first-child &,
        .bazis-datetime-control & {
            --margin-bottom: var(--bazis-margin-6x);
        }
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col:last-child &,
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col.bh-next-empty & {
            --margin-bottom: 0;
        }
    }
}

.sl-control-has-error .bazis-control {
    --border-color: var(--bazis-color-danger);
    --border-color-hover: var(--bazis-color-danger);
    --border-color-focus: var(--bazis-color-danger);
    --border-color-active: var(--bazis-color-danger);
}

app-button-search-grouped .bh-text-right {
    width: 100%;
}

.sl-range-fields {
    display: flex;
    gap: var(--bazis-margin-2x);

    &__delimiter {
        padding-top: var(--bazis-padding-2x);
    }

    bazis-input-number {
        --control-width: 100%;
        display: contents;
    }
}
