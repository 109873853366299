.bazis-checkbox {
    --background-checked: var(--bazis-color-primary);
    --border-color-checked: var(--bazis-color-primary);

    .sl-item--selected_partial & {
        &__label::before {
            content: '—';
            border-color: var(--border-color-checked);
            background: var(--background-checked);

            color: var(--checkmark-color);
        }
    }

    .sl-col_selectable .bazis-control & {
        &__label::before {
            margin-right: var(--bazis-margin-6x);
        }
    }

    @media (max-width: 1024px) {
        .sl-toolbar_table-header .bazis-list_vline .bazis-control & {
            margin-left: 0;
        }
    }
}
