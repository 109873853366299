.bazis-switcher {
    /**
     * @prop --color: цвет метки флажка
     * @prop --color-hover: цвет метки флажка при наведеннии
     * @prop --color-checked: цвет метки при установленном флажке
     * @prop --color-disabled: цвет метки при отключенном флажке
     */
    @include props-font;

    --color: inherit;
    --color-hover: var(--color);
    --color-checked: var(--color);
    --color-disabled: var(--color);

    bazis-toggle {
        vertical-align: middle;
    }

    &:hover {
        .bazis-switcher__label {
            color: var(--color-hover);
        }
    }

    &__label {
        @include font;
        vertical-align: middle;
        color: var(--color);

        .bazis-toggle--checked + & {
            color: var(--color-checked);
        }

        .bazis-toggle--disabled + &,
        .bazis-switcher:hover .bazis-toggle--disabled + & {
            color: var(--color-disabled);
        }
    }
}
