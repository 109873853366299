h1,
.bazis-h1 {
    + .sl-h1_subtitle {
        padding-bottom: var(--bazis-padding-8x);
    }

    @media (max-width: 1024px) {
        app-draft-bid &.bh-no-margin {
            margin-top: var(--bazis-margin-8x);
        }
    }
}

h2 {
    font-weight: 500;
}

h6,
.bazis-h6 {
    aside.sl-aside_right & {
        --bazis-h6-margin: 0 0 var(--bazis-margin-2x);
    }
    aside.sl-aside_right bazis-toolbar &.bazis-h6_minor {
        --bazis-h6-margin-minor: 0;
    }

    .sl-page_edit & {
        --bazis-h6-margin: 0 0 var(--bazis-margin-5x);
    }

    .bazis-block_popup &.bazis-h6_minor {
        --bazis-line-height-h6-minor: var(--bazis-margin-bottom-6x);
        --bazis-h6-margin: 0 0 var(--bazis-margin-4x);
    }

    .sl-block_telemetry-statistic & {
        --bazis-font-size-h6: 14px;
    }
}

p {
    .sl-grid_table &:first-child:not(.bazis-control__error p) {
        margin-top: 0;
    }
}

.sl-letter-spacing {
    &_05 {
        letter-spacing: 0.5px;
    }
    &_025 {
        letter-spacing: 0.25px;
    }
}
