// implemented
.bazis-empty {
    --margin-top: 120px;
    --font-size: 13px;
    --line-height: 20px;
    --color: var(--bazis-text-color-secondary);
    max-width: 548px;
    margin-left: auto;
    margin-right: auto;

    h5 {
        margin-bottom: var(--bazis-margin-2x);
    }

    bazis-button:not(.bazis-control__field-group bazis-button) {
        margin-top: var(--bazis-margin-6x);
    }
}
