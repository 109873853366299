.bazis-dialog {
    @include props-block-model;
    @include props-margin;
    @include props-width;
    @include props-colors;

    --position-start: inherit;
    --position-end: inherit;
    --position-top: inherit;
    --position-bottom: inherit;

    --background-hover: var(--background);
    --border-color-hover: var(--border-color);
    --box-shadow-hover: var(--box-shadow);

    @include block-model;
    @include margin;
    @include width;
    @include colors;

    &__footer,
    &__header {
        @include props-block-model;
        @include props-margin;

        @include block-model;
        @include margin;
    }

    &__header {
    }

    &__content {
        @include props-block-model;
        @include block-model;
    }

    // types popover
    &_top {
        top: var(--position-top);
        left: var(--position-start);
        right: var(--position-end);
        bottom: auto;
    }
    &_bottom {
        bottom: var(--position-bottom);
        left: var(--position-start);
        right: var(--position-end);
        top: auto;
    }
    &_left {
        top: var(--position-top);
        bottom: var(--position-bottom);
        left: var(--position-start);
    }
    &_left_top {
        top: var(--position-top);
        left: var(--position-start);
        right: auto;
        bottom: auto;
    }
    &_left_bottom {
        bottom: var(--position-bottom);
        left: var(--position-start);
        right: auto;
        top: auto;
    }
    &_right {
        top: var(--position-top);
        bottom: var(--position-bottom);
        right: var(--position-end);
        left: auto;
    }
    &_right_top {
        top: var(--position-top);
        right: var(--position-end);
        left: auto;
        bottom: auto;
    }
    &_right_bottom {
        bottom: var(--position-bottom);
        right: var(--position-end);
        left: auto;
        top: auto;
    }
}
