*,
html {
    scrollbar-color: var(--bazis-background-dark) var(--bazis-border-color-dark);
    scrollbar-width: thin;
}
@supports selector(::-webkit-scrollbar) {
    *,
    html {
        scrollbar-color: unset;
        scrollbar-width: unset;
    }
    *::-webkit-scrollbar,
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        -webkit-overflow-scrolling: auto;
        width: 4px;
        height: 4px;
    }
    *::-webkit-scrollbar-track,
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        border-radius: var(--bazis-border-radius);
        background: var(--bazis-background-dark);
    }
    *::-webkit-scrollbar-track:horizontal,
    ::-webkit-scrollbar-track:horizontal {
        -webkit-box-shadow: none;
    }
    *::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb {
        border-radius: var(--bazis-border-radius);
        background: var(--bazis-border-color-dark);
    }
}

// ::-webkit-scrollbar              { /* 1 - скроллбар */ }
// ::-webkit-scrollbar-button       { /* 2 - кнопка */ }
// ::-webkit-scrollbar-track        { /* 3 - трек */ }
// ::-webkit-scrollbar-track-piece  { /* 4 - видимая часть трека */ }
// ::-webkit-scrollbar-thumb        { /* 5 - ползунок */ }
// ::-webkit-scrollbar-corner       { /* 6 - уголок */ }
// ::-webkit-resizer                { /* 7 - изменение размеров */ }

// :horizontal
// :vertical
// :decrement
// :increment
// :start
// :end 
// :double-button
// :single-button
// :no-button
// :corner-present
// :window-inactive