.sl-status-save {
    --margin-start: 0;
    --margin-end: 0;
    --margin-bottom: 0;
    --font-size: 13px;
    --font-weight: 400;
    --color: var(--bazis-text-color);

    bazis-icon {
        margin-right: var(--bazis-margin-2x);
    }
}
