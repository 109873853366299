.bazis-options-content {
    --margin-top: var(--bazis-margin-4x);
    --margin-bottom: var(--bazis-margin-4x);
    --max-height: 224px;

    .bazis-list {
        --padding-top: var(--bazis-padding-2x);
        --padding-bottom: var(--bazis-padding-2x);
        //     --border-radius-list: var(--bazis-border-radius);
        //     --border-width-list: 1px;
        //     --border-style-list: solid;
        //     --border-color-list: var(--bazis-background-medium);
        //     --padding-top-list: var(--bazis-padding-3x);
        //     --padding-bottom-list: var(--bazis-padding-3x);
        //     --margin-top-list: 3px;
    }
    .bazis-item {
        &_group {
            --background-hover: var(--background);
            --background-selected: var(--background);

            --color: var(--bazis-text-color-secondary);
            --color-hover: var(--color);
            --color-selected: var(--color);
            user-select: none;
        }

        .bazis-list {
            --padding-top-list: var(--bazis-padding-2x);
        }
    }
}
