aside {
    &.bh-sticky {
        overflow-y: auto;
        max-height: calc(100vh - 150px);
        margin-right: calc(var(--bazis-margin-2x) * -1);
        padding-right: var(--bazis-padding-2x);
    }

    app-bid-summary & {
        margin-top: calc(var(--bazis-line-height-h3) + var(--bazis-margin-4x) + var(--bazis-margin-8x));
    }

    &.sl-aside_right {
        @media (max-width: 1024px) {
            margin-top: var(--bazis-margin-8x);

            bazis-button.bh-block,
            app-view-entity-transits-widget .bazis-item bazis-button {
                display: inline-block;
            }
        }
        @media (max-width: 576px) {
            bazis-button.bh-block,
            app-view-entity-transits-widget .bazis-item bazis-button {
                display: block;
            }
        }
    }
}
