.bazis-panel {
    @include props-block-model;
    @include props-margin;
    @include props-width;
    @include props-colors;

    --position-start: inherit;
    --position-end: inherit;
    --position-top: inherit;
    --position-bottom: inherit;

    --background-hover: var(--background);
    --border-color-hover: var(--border-color);
    --box-shadow-hover: var(--box-shadow);

    @include block-model;
    @include margin;
    @include width;
    @include colors;

    // --height-header: initial;

    // height: 100%;

    &__footer,
    &__header {
        @include props-block-model;
        @include props-margin;

        @include block-model;
        @include margin;
    }

    &__header {
        // height: var(--height-header);
    }

    &__content {
        @include props-block-model;
        @include block-model;
        // height: calc(100% - var(--height-header));
    }

    // types popover
    &_top[popover] {
        top: var(--position-top);
        left: var(--position-start);
        right: var(--position-end);
        bottom: auto;
    }
    &_bottom[popover] {
        bottom: var(--position-bottom);
        left: var(--position-start);
        right: var(--position-end);
        top: auto;
    }
    &_left[popover] {
        top: var(--position-top);
        bottom: var(--position-bottom);
        left: var(--position-start);
    }
    &_left_top[popover] {
        top: var(--position-top);
        left: var(--position-start);
        right: auto;
        bottom: auto;
    }
    &_left_bottom[popover] {
        bottom: var(--position-bottom);
        left: var(--position-start);
        right: auto;
        top: auto;
    }
    &_right[popover] {
        top: var(--position-top);
        bottom: var(--position-bottom);
        right: var(--position-end);
        left: auto;
    }
    &_right_top[popover] {
        top: var(--position-top);
        right: var(--position-end);
        left: auto;
        bottom: auto;
    }
    &_right_bottom[popover] {
        bottom: var(--position-bottom);
        right: var(--position-end);
        left: auto;
        top: auto;
    }

    // state
    &--expanded {
    }
}
