.bazis-vertical-bars {
    --height-bars: 300px;
    position: relative;

    &__guides {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: var(--height-bars);
        border-bottom: 1px solid var(--bazis-border-color);

        &__item {
            --top: initial;
            position: absolute;
            top: var(--top);
            right: 0;
            left: 0;
            height: 1px;
            background: var(--bazis-border-color);
            opacity: 0;
        }
    }

    &__scroll-content {
        padding: 0 0 var(--bazis-padding-3x);
        overflow-x: auto;
        overflow-y: hidden;
    }

    &__wrapper {
        margin-bottom: var(--bazis-margin-8x);
        display: flex;
        gap: var(--bazis-margin-2x);
        align-items: flex-end;


        hr {
            margin: 0;
            position: absolute;
            left: 0;
            right: 0;
        }
    }

    &__item {
        position: relative;
        min-width: 80px;
        flex: 1 1 auto;

        &__value {
            margin-bottom: var(--bazis-margin-2x);
            font-size: 11px;
            line-height: 12px;
            text-align: center;
        }

        &__bar {
            border-radius: var(--bazis-border-radius) var(--bazis-border-radius) 0 0;
            background: var(--bazis-color-primary);
            opacity: 0.35;
            will-change: opacity;
            transition: opacity 100ms linear;

            .bazis-vertical-bars__item--active & {
                opacity: 1;
            }
        }

        &__title {
            position: absolute;
            left: 0;
            right: 0;
            bottom: calc(var(--bazis-margin-8x) * -1);
            overflow: hidden;
            font-size: 11px;
            line-height: 12px;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: var(--bazis-text-color-secondary);

            .bazis-vertical-bars__item--active & {
                color: var(--bazis-text-color);
            }
        }
    }
}
