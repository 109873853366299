.bh-align-self-start {
    align-self: flex-start !important;
}

.bh-align-self-end {
    align-self: flex-end !important;
}

.bh-align-self-center {
    align-self: center !important;
}

.bh-align-self-stretch {
    align-self: stretch !important;
}

.bh-align-self-baseline {
    align-self: baseline !important;
}

.bh-align-self-auto {
    align-self: auto !important;
}

.bh-flex-wrap {
    flex-wrap: wrap !important;
}

.bh-flex-nowrap {
    flex-wrap: nowrap !important;
}

.bh-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.bh-justify-content-start {
    justify-content: flex-start !important;
}

.bh-justify-content-center {
    justify-content: center !important;
}

.bh-justify-content-end {
    justify-content: flex-end !important;
}

.bh-justify-content-around {
    justify-content: space-around !important;
}

.bh-justify-content-between {
    justify-content: space-between !important;
}

.bh-justify-content-evenly {
    justify-content: space-evenly !important;
}

.bh-align-items-start {
    align-items: flex-start !important;
}

.bh-align-items-center {
    align-items: center !important;
}

.bh-align-items-end {
    align-items: flex-end !important;
}

.bh-align-items-stretch {
    align-items: stretch !important;
}

.bh-align-items-baseline {
    align-items: baseline !important;
}

.bh-shrink {
    flex-shrink: 0;
}

.bh-flex-row {
    flex-direction: row;
}

.bh-flex-column {
    @extend .bh-flex;
    flex-direction: column;
}

.bh-flex-row-reverse {
    @extend .bh-flex;
    flex-direction: row-reverse;
}

.bh-flex-column-reverse {
    @extend .bh-flex;
    flex-direction: column-reverse;
}

.bh-flex-all-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

@media (min-width: 576.98px) {
    .bh-align-self-sm-start {
        align-self: flex-start !important;
    }

    .bh-align-self-sm-end {
        align-self: flex-end !important;
    }

    .bh-align-self-sm-center {
        align-self: center !important;
    }

    .bh-align-self-sm-stretch {
        align-self: stretch !important;
    }

    .bh-align-self-sm-baseline {
        align-self: baseline !important;
    }

    .bh-align-self-sm-auto {
        align-self: auto !important;
    }

    .bh-flex-wrap-sm {
        flex-wrap: wrap !important;
    }

    .bh-flex-nowrap-sm {
        flex-wrap: nowrap !important;
    }

    .bh-flex-wrap-reverse-sm {
        flex-wrap: wrap-reverse !important;
    }

    .bh-justify-content-sm-start {
        justify-content: flex-start !important;
    }

    .bh-justify-content-sm-center {
        justify-content: center !important;
    }

    .bh-justify-content-sm-end {
        justify-content: flex-end !important;
    }

    .bh-justify-content-sm-around {
        justify-content: space-around !important;
    }

    .bh-justify-content-sm-between {
        justify-content: space-between !important;
    }

    .bh-justify-content-sm-evenly {
        justify-content: space-evenly !important;
    }

    .bh-align-items-sm-start {
        align-items: flex-start !important;
    }

    .bh-align-items-sm-center {
        align-items: center !important;
    }

    .bh-align-items-sm-end {
        align-items: flex-end !important;
    }

    .bh-align-items-sm-stretch {
        align-items: stretch !important;
    }

    .bh-align-items-sm-baseline {
        align-items: baseline !important;
    }

    .bh-flex-sm-row {
        flex-direction: row;
    }

    .bh-flex-sm-column {
        flex-direction: column;
    }

    .bh-flex-sm-row-reverse {
        flex-direction: row-reverse;
    }

    .bh-flex-sm-column-reverse {
        flex-direction: column-reverse;
    }
}
@media (min-width: 768.98px) {
    .bh-align-self-md-start {
        align-self: flex-start !important;
    }

    .bh-align-self-md-end {
        align-self: flex-end !important;
    }

    .bh-align-self-md-center {
        align-self: center !important;
    }

    .bh-align-self-md-stretch {
        align-self: stretch !important;
    }

    .bh-align-self-md-baseline {
        align-self: baseline !important;
    }

    .bh-align-self-md-auto {
        align-self: auto !important;
    }

    .bh-flex-wrap-md {
        flex-wrap: wrap !important;
    }

    .bh-flex-nowrap-md {
        flex-wrap: nowrap !important;
    }

    .bh-flex-wrap-reverse-md {
        flex-wrap: wrap-reverse !important;
    }

    .bh-justify-content-md-start {
        justify-content: flex-start !important;
    }

    .bh-justify-content-md-center {
        justify-content: center !important;
    }

    .bh-justify-content-md-end {
        justify-content: flex-end !important;
    }

    .bh-justify-content-md-around {
        justify-content: space-around !important;
    }

    .bh-justify-content-md-between {
        justify-content: space-between !important;
    }

    .bh-justify-content-md-evenly {
        justify-content: space-evenly !important;
    }

    .bh-align-items-md-start {
        align-items: flex-start !important;
    }

    .bh-align-items-md-center {
        align-items: center !important;
    }

    .bh-align-items-md-end {
        align-items: flex-end !important;
    }

    .bh-align-items-md-stretch {
        align-items: stretch !important;
    }

    .bh-align-items-md-baseline {
        align-items: baseline !important;
    }

    .bh-flex-md-row {
        flex-direction: row;
    }

    .bh-flex-md-column {
        flex-direction: column;
    }

    .bh-flex-md-row-reverse {
        flex-direction: row-reverse;
    }

    .bh-flex-md-column-reverse {
        flex-direction: column-reverse;
    }
}
@media (min-width: 992.98px) {
    .bh-align-self-lg-start {
        align-self: flex-start !important;
    }

    .bh-align-self-lg-end {
        align-self: flex-end !important;
    }

    .bh-align-self-lg-center {
        align-self: center !important;
    }

    .bh-align-self-lg-stretch {
        align-self: stretch !important;
    }

    .bh-align-self-lg-baseline {
        align-self: baseline !important;
    }

    .bh-align-self-lg-auto {
        align-self: auto !important;
    }

    .bh-flex-wrap-lg {
        flex-wrap: wrap !important;
    }

    .bh-flex-nowrap-lg {
        flex-wrap: nowrap !important;
    }

    .bh-flex-wrap-reverse-lg {
        flex-wrap: wrap-reverse !important;
    }

    .bh-justify-content-lg-start {
        justify-content: flex-start !important;
    }

    .bh-justify-content-lg-center {
        justify-content: center !important;
    }

    .bh-justify-content-lg-end {
        justify-content: flex-end !important;
    }

    .bh-justify-content-lg-around {
        justify-content: space-around !important;
    }

    .bh-justify-content-lg-between {
        justify-content: space-between !important;
    }

    .bh-justify-content-lg-evenly {
        justify-content: space-evenly !important;
    }

    .bh-align-items-lg-start {
        align-items: flex-start !important;
    }

    .bh-align-items-lg-center {
        align-items: center !important;
    }

    .bh-align-items-lg-end {
        align-items: flex-end !important;
    }

    .bh-align-items-lg-stretch {
        align-items: stretch !important;
    }

    .bh-align-items-lg-baseline {
        align-items: baseline !important;
    }

    .bh-flex-lg-row {
        flex-direction: row;
    }

    .bh-flex-lg-column {
        flex-direction: column;
    }

    .bh-flex-lg-row-reverse {
        flex-direction: row-reverse;
    }

    .bh-flex-lg-column-reverse {
        flex-direction: column-reverse;
    }
}
@media (min-width: 1024.98px) {
    .bh-align-self-xl-start {
        align-self: flex-start !important;
    }

    .bh-align-self-xl-end {
        align-self: flex-end !important;
    }

    .bh-align-self-xl-center {
        align-self: center !important;
    }

    .bh-align-self-xl-stretch {
        align-self: stretch !important;
    }

    .bh-align-self-xl-baseline {
        align-self: baseline !important;
    }

    .bh-align-self-xl-auto {
        align-self: auto !important;
    }

    .bh-flex-wrap-xl {
        flex-wrap: wrap !important;
    }

    .bh-flex-nowrap-xl {
        flex-wrap: nowrap !important;
    }

    .bh-flex-wrap-reverse-xl {
        flex-wrap: wrap-reverse !important;
    }

    .bh-justify-content-xl-start {
        justify-content: flex-start !important;
    }

    .bh-justify-content-xl-center {
        justify-content: center !important;
    }

    .bh-justify-content-xl-end {
        justify-content: flex-end !important;
    }

    .bh-justify-content-xl-around {
        justify-content: space-around !important;
    }

    .bh-justify-content-xl-between {
        justify-content: space-between !important;
    }

    .bh-justify-content-xl-evenly {
        justify-content: space-evenly !important;
    }

    .bh-align-items-xl-start {
        align-items: flex-start !important;
    }

    .bh-align-items-xl-center {
        align-items: center !important;
    }

    .bh-align-items-xl-end {
        align-items: flex-end !important;
    }

    .bh-align-items-xl-stretch {
        align-items: stretch !important;
    }

    .bh-align-items-xl-baseline {
        align-items: baseline !important;
    }

    .bh-flex-xl-row {
        flex-direction: row;
    }

    .bh-flex-xl-column {
        flex-direction: column;
    }

    .bh-flex-xl-row-reverse {
        flex-direction: row-reverse;
    }

    .bh-flex-xl-column-reverse {
        flex-direction: column-reverse;
    }
}
@media (min-width: 1280.98px) {
    .bh-align-self-xxl-start {
        align-self: flex-start !important;
    }

    .bh-align-self-xxl-end {
        align-self: flex-end !important;
    }

    .bh-align-self-xxl-center {
        align-self: center !important;
    }

    .bh-align-self-xxl-stretch {
        align-self: stretch !important;
    }

    .bh-align-self-xxl-baseline {
        align-self: baseline !important;
    }

    .bh-align-self-xxl-auto {
        align-self: auto !important;
    }

    .bh-flex-wrap-xxl {
        flex-wrap: wrap !important;
    }

    .bh-flex-nowrap-xxl {
        flex-wrap: nowrap !important;
    }

    .bh-flex-wrap-reverse-xxl {
        flex-wrap: wrap-reverse !important;
    }

    .bh-justify-content-xxl-start {
        justify-content: flex-start !important;
    }

    .bh-justify-content-xxl-center {
        justify-content: center !important;
    }

    .bh-justify-content-xxl-end {
        justify-content: flex-end !important;
    }

    .bh-justify-content-xxl-around {
        justify-content: space-around !important;
    }

    .bh-justify-content-xxl-between {
        justify-content: space-between !important;
    }

    .bh-justify-content-xxl-evenly {
        justify-content: space-evenly !important;
    }

    .bh-align-items-xxl-start {
        align-items: flex-start !important;
    }

    .bh-align-items-xxl-center {
        align-items: center !important;
    }

    .bh-align-items-xxl-end {
        align-items: flex-end !important;
    }

    .bh-align-items-xxl-stretch {
        align-items: stretch !important;
    }

    .bh-align-items-xxl-baseline {
        align-items: baseline !important;
    }

    .bh-flex-xxl-row {
        flex-direction: row;
    }

    .bh-flex-xxl-column {
        flex-direction: column;
    }

    .bh-flex-xxl-row-reverse {
        flex-direction: row-reverse;
    }

    .bh-flex-xxl-column-reverse {
        flex-direction: column-reverse;
    }
}
