.bazis-infinite-scroll-content {
    .sl-filter app-search-filter .bazis-list_control-dd & {
        padding-bottom: 60px;

        .bazis-item + bazis-button {
            position: fixed;
            z-index: 150;
            bottom: 12px;
            left: 20px;
            right: 20px;
        }

        @media (min-width: 992.98px) {
            overflow: auto;
            --max-height-infinite-scroll-content: 275px;
        }
    }
}

bazis-infinite-list.bazis-list_control-dd {
    --border-width-list: 0;
    --box-shadow: none;
    --background-list: transparent;
    --padding-top-list: 0;
    --padding-bottom-list: 0;

    .bazis-infinite-scroll-content {
        border: 1px solid var(--bazis-background-medium);
        border-radius: var(--bazis-border-radius);
        padding-top: var(--bazis-padding-3x);
        padding-bottom: var(--bazis-padding-3x);
        background: var(--bazis-color-white);
        box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
    }
}
