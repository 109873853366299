.bazis-loadmore-button {
    .sl-page_detail & {
        text-align: left;

        bazis-button {
            --bazis-color-base: var(--bazis-color-action) !important;
            --bazis-color-shade: var(--bazis-color-action-shade) !important;
            --bazis-color-tint: var(--bazis-color-primary-tint) !important;

            --background: var(--bazis-color-white);
            --background-hover: var(--bazis-background-medium);
            --background-activated: var(--bazis-color-info-tint);
            --background-disabled: var(--bazis-background-dark);
            --border-color: var(--bazis-border-color-dark);
            --border-color-hover: var(--bazis-text-color-secondary);
            --border-color-focused: transparent;
            --border-color-disabled: var(--bazis-background-dark);
            --border-width: 1px;
            --border-style: solid;
            // --color: var(--bazis-text-color-secondary);
            // --color-disabled: var(--bazis-text-color-secondary);

            font-size: 14px;

            bazis-icon {
                display: none;
            }
        }
    }
}
