.bazis-contacts-control {
    &__group {
        app-edit-field &,
        app-draft-bid & {
            --padding-top: var(--bazis-margin-8x);
            --padding-start: var(--bazis-margin-8x);
            --padding-end: var(--bazis-margin-8x);
            --padding-bottom: var(--bazis-margin-8x);
            --margin-bottom: var(--bazis-margin-6x);
            --background: var(--bazis-background-accent);

            &:last-of-type {
                margin-bottom: var(--margin-bottom);
            }

            bazis-row:last-child .bazis-control {
                --margin-bottom: 0;
            }
        }
    }

    @media (max-width: 768px) {
        app-edit-field &,
        app-draft-bid & {
            .bazis-grid_form bazis-row:last-child {
                .bazis-control {
                    --margin-bottom: var(--bazis-margin-6x);
                }

                bazis-col:last-child .bazis-control {
                    --margin-bottom: 0;
                }
            }
        }
    }
}
