.bazis-chat__control {
    // position: relative;
    // display: flex;
    // gap: var(--bazis-margin-6x);

    .bazis-modal & {
        padding-top: var(--bazis-padding-4x);
        padding-bottom: var(--bazis-padding-4x);
        border-top: 1px solid var(--bazis-border-color);
        margin-top: var(--bazis-margin-6x);
        margin-left: calc(var(--padding-start-content) * -1);
        margin-right: calc(var(--padding-end-content) * -1);
        padding-left: var(--padding-start-content);
        padding-right: var(--padding-start-content);
    }

    // &__emojis {
    //     // bazis-emojis {
    //     //     display: none;
    //     // }

    //     // &:hover bazis-emojis {
    //     //     display: block;
    //     // }
    // }

    &.bazis-control {
        --margin-bottom: 0;
        --padding-field-end: calc(var(--bazis-padding-3x) / 2);

        .bazis-control__field-group__start + .bazis-control__field-wrapper {
            --padding-field-start: 0;
        }
    }

    .bazis-control {
        --margin-bottom: 0;
    }

    .bazis-control {
        &__field {
            display: block;
            width: 100%;
            min-height: 18px;
            height: auto;
            max-height: 100%;
            margin: var(--bazis-margin-2x) 0;
            word-break: break-word;
            white-space: pre-line;
            line-height: 18px;

            &:empty::before {
                content: attr(data-placeholder);
                display: block; /* Важное требование для комфортной работы в Firefox */
                pointer-events: none; /* Исключаем взаимодействие с подсказкой */
                line-height: 18px;
                color: var(--bazis-placeholder-color);
            }

            img {
                display: block;
                width: calc(var(--width-photo) - 2px);
                height: calc(var(--width-photo) - 1px);

                &.bazis-chat__emoticon {
                    display: inline-block;
                    vertical-align: middle;
                    width: 20px;
                    height: 20px;
                }
            }

            &-group {
                align-items: flex-end;
                padding-right: calc(var(--bazis-padding-3x) / 2);

                bazis-button {
                    --height: 34px;
                }

                &.bazis-control__field-group--focused:hover {
                    background: var(--background-focus);
                    border-color: var(--border-color-focus);
                    border-style: var(--border-style-focus);
                }

                &__start {
                    bazis-button {
                        --padding-start: var(--bazis-padding-3x);
                        --padding-end: var(--bazis-padding-3x);
                    }
                }
                &__end {
                    bazis-button {
                        --padding-start: calc(var(--bazis-padding-3x) / 2);
                        --padding-end: calc(var(--bazis-padding-3x) / 2);

                        &.bazis-color {
                            // --color: var(--bazis-color-base);
                            color: var(--bazis-color-base);

                            bazis-icon {
                                color: currentColor;
                            }
                        }
                    }

                    .bazis-chat__control__emojis bazis-button {
                        --color-hover: var(--color);
                        --color-focused: var(--color);
                        --color-activated: var(--color);
                    }
                }
            }
            &-wrapper {
            }
        }
    }
}

// .bazis-chat__field {
//     white-space: pre-line;
//     width: 100%;
//     min-height: 18px;
//     height: auto;
//     max-height: 100%;
//     margin: var(--bazis-margin-2x) 0;
//     word-break: break-word;
//     line-height: 18px;

//     &:focus-visible {
//         border: none;
//         outline: none;
//     }

//     &:empty::before {
//         content: attr(data-placeholder);
//         display: block; /* Важное требование для комфортной работы в Firefox */
//         pointer-events: none; /* Исключаем взаимодействие с подсказкой */
//         line-height: 18px;
//         color: var(--bazis-placeholder-color);
//     }

//     img {
//         display: block;
//         width: calc(var(--width-photo) - 2px);
//         height: calc(var(--width-photo) - 1px);

//         &.bazis-chat__emoticon {
//             display: inline-block;
//             vertical-align: middle;
//             width: 20px;
//             height: 20px;
//         }
//     }

//     &-group {
//         display: flex;
//         width: 100%;
//         gap: var(--bazis-margin-4x);
//     }

//     &-wrapper {
//         display: flex;
//         gap: var(--bazis-margin-3x);
//         overflow-y: auto;
//         overflow-x: hidden;

//         width: 100%;
//         min-height: 36px;
//         height: auto;
//         max-height: 108px; // 36 * 3
//         border: 1px solid var(--bazis-border-color);
//         border-radius: var(--bazis-border-radius);
//         padding-left: var(--bazis-padding-3x);

//         .bazis-control {
//             --control-width: 36px;
//             flex-shrink: 0;

//             bazis-button {
//                 --height: 34px;
//                 --border-width: 0;
//                 width: var(--control-width);

//                 bazis-icon {
//                     color: var(--bazis-placeholder-color);
//                 }
//             }
//         }

//         &--focused {
//             border-color: var(--bazis-color-primary);
//         }
//     }
// }
