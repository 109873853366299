.bazis-empty {
    @include props-padding;
    @include props-colors;
    @include props-font;
    @include padding;
    --margin-top: 0;
    --margin-bottom: 0;

    margin-top: var(--margin-top);
    margin-bottom: var(--margin-bottom);
    background: var(--background);

    p {
        @include font();
        color: var(--color);
    }

    text-align: center;
}
