bazis-select-native {
    display: contents;
}

// https://web-standards.ru/articles/native-and-custom-select/
// Both native and custom selects must have the same width/height.
bazis-select-native select,
.bazis-select {
    position: relative;
    width: 100%;
    height: 100%;
}

// Make sure the custom select does not mess with the layout
.bazis-select {
    --padding-start: initial;
    --padding-end: initial;
    --border-radius: initial;
    --background: initial;
    --background-hover: initial;
    --background-focus: initial;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    padding-left: var(--padding-start);
    padding-right: var(--padding-end);
    border-radius: var(--border-radius);
}

// This media query detects devices where the primary
// input mechanism can hover over elements. (e.g. computers with a mouse)
// @media (hover: hover) {
// Since we are using a mouse, it's safe to show the custom select.
.bazis-select {
    display: block;
}

// In a computer using keyboard? Then let's hide back the custom select
// while the native one is focused:
bazis-select-native:focus + .bazis-select {
    display: none;
}

//
// Rest of the styles to create the custom select.
// Just make sure the native and the custom have a similar "box" (the trigger).
//

bazis-select-native select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    outline: 0;
    margin-left: var(--bazis-margin-6x); // невидимый запас под возможную иконку
    // TODO: подумать, как лучше оформить, так как иконка может быть не всегда

    app-select-filter & {
        // что это делает в базисе?
        margin-left: 0;
    }

    padding-right: 0;

    option {
        height: calc(var(--height) - 2 * var(--border-width));
        padding-block-end: 0;
        padding-inline: 0;
        line-height: calc(var(--height) - 2 * var(--border-width)) !important;
    }
}

.bazis-select__trigger {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--background);
    cursor: pointer;
    line-height: inherit;

    .bazis-control__field-group:hover & {
        background: var(--background-hover);
    }

    // &:focus,
    // &:focus:hover,
    .bazis-control__field-group:focus &,
    .bazis-control__field-group:focus:hover & {
        background: var(--background-focus);
    }
    .bazis-control__field-group:active &,
    .bazis-control__field-group:active:hover & {
        background: var(--background-active);
    }

    span {
        @include text-ellipsis;
    }

    & + .bazis-control__field {
        // --padding-field-start: 0;
        // --padding-field-end: 0;
        position: absolute;
        top: 0;
        opacity: 0;
        background: var(--bazis-background);

        &--focused {
            opacity: 1;
        }
    }
}

.bazis-select {
    .bazis-control__field & .bazis-control__field {
        --padding-field-start: 0;
        --padding-field-end: 0;
    }
}

.bazis-select-text {
    @include props-margin;
    @include margin;

    // position: relative;
    display: inline-block;

    &__label {
        @include props-font;
        @include font;
    }
}
