app-map {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

// implemented
.bazis-map-wrapper {
    &.sl-map-wrapper_medium {
        --height: 400px;
        --margin-bottom: var(--bazis-margin-8x);
    }

    &.sl-map-wrapper_large {
        --height: 700px;
    }

    app-route-widget & {
        --height: 400px;
    }
}

.bazis-map-circle {
    float: none;
    flex-shrink: 0;
    margin-left: var(--bazis-margin-8x);
}

.sl-map-wrapper_dd {
    @extend .bazis-map-wrapper;
    --height: 100px;
    --width: 350px;
    --margin-top: var(--bazis-margin-2x);
    border: 1px solid var(--bazis-border-color);
    border-radius: var(--bazis-border-radius);

    @media (max-width: 1180px) {
        --height: 106px;
    }
}

.sl-btn-onmap-delete {
    z-index: 100;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.bazis-map__base-control {
    & > * {
        margin-bottom: var(--bazis-margin-5x);
    }
}

@media (max-width: 576px) {
    app-route-widget .bazis-map-wrapper {
        --height: 184px;
        border: 1px solid var(--bazis-border-color);
        border-radius: 8px;

        .bazis-map {
            border-radius: 8px;
        }

        .sl-open-btn,
        .sl-mobile-content {
            position: absolute;
            top: 8px;
            left: 8px;
            z-index: 40;
        }

        .sl-mobile-content {
            right: 8px;
        }

        .sl-mobile-content:not(.bazis-modal .sl-mobile-content) {
            display: none;
        }
    }
}
