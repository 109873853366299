bazis-gallery-slider.sl-gallery_square {
    display: block;
    margin-bottom: var(--bazis-margin-8x);

    .bazis-list_gallery {
        > .bazis-item {
            --margin-end: var(--bazis-margin-1x);
        }

        .bazis-figure {
            --width: 260px;
            --height: 260px;
            --border-width: 0;
            cursor: pointer;

            bazis-button {
                position: absolute;
                top: 8px;
                left: 8px;
            }

            &_medium {
                --width: 336px;
            }
            &_btn {
                --border-width: 1px;
                display: flex;
            }
        }
    }
}
