bazis-datepicker {
    --background-selected: var(--bazis-color-primary);
    --background-interval: rgba(var(--bazis-color-primary-rgb), 0.1);
    --background-current: transparent;

    --padding-start: var(--bazis-padding-4x);
    --padding-end: var(--bazis-padding-4x);
    --padding-top: var(--bazis-padding-4x);
    --padding-bottom: var(--bazis-padding-4x);

    --color-current: var(--bazis-color-primary);

    --gap-days: 18px;

    --mark-background-confirmed: var(--bazis-color-danger);
    --mark-background-unconfirmed: var(--bazis-color-warning);
    --mark-background-other: var(--bazis-color-secondary);

    &::part(mark) {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        bottom: 2px;
        left: 0;
        right: 0;
        margin: auto;
    }
}
