bazis-entity-transits bazis-toolbar,
.bazis-cookie,
bazis-button-search .bazis-block__footer {
    bazis-button {
        &.bazis-btn_solid.bazis-color-action:not(.bazis-btn--disabled)::part(native) {
            --background: var(--bazis-color-primary);
            --background-hover: var(--bazis-color-primary-shade);
            --background-activated: var(--bazis-color-primary-tint);
            --color: var(--bazis-color-primary-contrast);
            --color-rgb: var(--bazis-color-primary-contrast-rgb);
            --color-hover: var(--bazis-color-primary-contrast);
            --color-focused: var(--bazis-color-primary-contrast);
            --color-activated: var(--bazis-color-primary-contrast);
        }
        &.bazis-btn_outline.bazis-color-action:not(.bazis-btn--disabled)::part(native) {
            --color: var(--bazis-color-primary);
            --color-rgb: var(--bazis-color-primary-rgb);
            --color-hover: rgba(var(--bazis-color-primary-shade-rgb), 0.8);
            --color-focused: rgba(var(--bazis-color-primary-rgb), 0.8);
            --color-activated: var(--bazis-color-primary);
        }
        &.bazis-btn_clear.bazis-color-action:not(.bazis-btn--disabled)::part(native) {
            --color: var(--bazis-color-primary);
            --color-rgb: var(--bazis-color-primary-rgb);
            --color-hover: var(--bazis-color-primary-shade);
            --color-focused: var(--bazis-color-primary-shade);
            --color-activated: var(--bazis-color-primary-tint);
        }
    }
}

.bazis-alert__buttons,
.bazis-modal.signing,
.bazis-select-during-date-control {
    bazis-button {
        &.bazis-btn_solid.bazis-color-action:not(.bazis-btn--disabled)::part(native) {
            --background: var(--bazis-color-primary);
            --background-hover: var(--bazis-color-primary-shade);
            --background-activated: var(--bazis-color-primary-tint);
            --color: var(--bazis-color-primary-contrast);
            --color-rgb: var(--bazis-color-primary-contrast-rgb);
            --color-hover: var(--bazis-color-primary-contrast);
            --color-focused: var(--bazis-color-primary-contrast);
            --color-activated: var(--bazis-color-primary-contrast);
        }
    }
}
