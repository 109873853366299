.sl-profile-page {
    bazis-tabs:not(.bazis-chat__media__header bazis-tabs) {
        margin-top: var(--bazis-margin-8x);
    }

    h6 {
        --bazis-h6-margin: 0 0 var(--bazis-margin-4x);
    }

    .sl-form__footer {
        padding-top: 0;
        margin-top: var(--bazis-margin-8x);
        border-top: none;
    }

    @media (max-width: 1024px) {
        padding-bottom: 120px;

        bazis-tabs {
            margin-top: var(--bazis-margin-4x);
        }

        .bazis-page {
            --padding-bottom: var(--bazis-padding-8x);

            &-header {
                margin-top: var(--bazis-padding-8x);

                h1 {
                    --bazis-h1-margin: 0 0 var(--bazis-margin-6x);
                }

                bazis-toolbar h1 {
                    --bazis-h1-margin: 0;
                }

                .bazis-list_vline > .bazis-item {
                    &:nth-child(3)::before {
                        display: none;
                    }
                    &:nth-child(3) > *,
                    &:nth-child(4) > * {
                        padding-top: var(--bazis-padding-4x);
                    }
                }
            }
        }
    }

    @media (max-width: 576px) {
        .bazis-page-header {
            bazis-toolbar {
                flex-wrap: nowrap;

                [slot='end'] {
                    justify-content: flex-end;
                }
            }
            .bazis-list_vline > .bazis-item {
                &::before {
                    display: none;
                }
                &:nth-child(2) > * {
                    padding-top: var(--bazis-padding-4x);
                }
            }
        }

        app-agency-contract-page .bazis-page-header bazis-toolbar {
            flex-wrap: wrap;

            [slot='end'] {
                // gap?
                margin-top: var(--bazis-margin-4x);
                justify-content: flex-start;
            }
        }

        .sl-section bazis-button,
        app-account-bank bazis-button {
            display: block;
            width: 100%;
        }

        app-account-bank {
            bazis-toolbar {
                flex-direction: column;

                &::part(start),
                &::part(end) {
                    width: 100%;
                }

                .bh-line-elements {
                    flex-direction: column;
                }
            }
        }
    }

    @media (max-width: 300px), (max-device-width: 300px) {
        .bazis-page-header bazis-toolbar {
            flex-wrap: wrap;

            [slot='end'] {
                margin-top: var(--bazis-margin-4x);
                justify-content: flex-start;
            }
        }
    }
}

@media (max-width: 1024px) {
    app-profile-page:not(.sl-profile-page-fl) ~ app-footer {
        margin-bottom: 69px;
    }
}
