.bazis-chat__reactions {
    display: flex;
    flex-wrap: wrap;
    gap: var(--bazis-margin-2x);
    margin-top: var(--bazis-margin-2x);

    &__item {
        display: flex;
        align-items: center;
        gap: var(--bazis-margin-2x);
        border-radius: 20px;
        padding: var(--bazis-padding-1x) var(--bazis-padding-1x) var(--bazis-padding-1x)
            var(--bazis-padding-2x);
        background: var(--bazis-background-dark);

        img {
            width: 16px;
            height: 16px;
            margin: var(--bazis-padding-1x);
        }

        bazis-avatar {
            --width: 40px;
            --height: 40px;
            font-size: 8px;
        }

        &__users {
            display: flex;
            flex-wrap: wrap;
            gap: var(--bazis-margin-1x);
        }
    }
}
