bazis-interrogation {
    --margin-start: var(--bazis-margin-3x);
    --color: var(--bazis-placeholder-color);

    .bazis-checkbox_right + & {
        --margin-start: 0;
        --margin-end: var(--bazis-margin-3x);

        // проверить на кросс браузерность
        display: inline-flex;
    }

    .bazis-block_accent & {
        --color: var(--bazis-text-color-secondary);
    }
}
