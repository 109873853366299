bazis-tooltip.bazis-tooltip_light {
    .sl-tooltip_history & {
        --width: 415px;
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);
        --padding-top: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-6x);

        overflow: visible;

        .bh-overhide {
            max-height: calc(var(--max-height) - var(--padding-top) - var(--padding-bottom));
            height: calc(100% - var(--padding-top) - var(--padding-bottom));
        }

        .bazis-list {
            --margin-top-list: var(--bazis-margin-4x);
            --margin-end-list: calc(var(--bazis-margin-5x) * -1);
            --padding-end-list: var(--bazis-padding-6x);
            overflow-y: auto;
            max-height: 308px;
        }

        .sl-btn_close {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }

    .sl-tooltip_rating & {
        --width: 400px;
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
        --padding-top: var(--bazis-padding-5x);
        --padding-bottom: var(--bazis-padding-5x);
        overflow: visible;
    }
}
