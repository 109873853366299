.sl-logs {
    word-wrap: break-word;
    overflow-y: auto;
    max-height: 400px;
}

app-profile-license-address {
    .sl-toolbar_table {
        --padding-top: var(--bazis-padding-4x);
        --padding-bottom: var(--bazis-padding-4x);

        &::part(start) {
            width: 100%;
            margin-bottom: 0;
        }
        // &::part(end) {
        //     margin-top: 0;
        // }

        [slot='start'] {
            display: block;
        }
    }
    // .bazis-paginator_secondary {
    //     padding-bottom: var(--bazis-padding-5x);
    // }

    .bazis-page {
        --padding-bottom: var(--bazis-padding-5x);
    }
}

app-analytics-date-diagram {
    bazis-select {
        --control-width: 188px;
        float: right;
        position: relative;
        margin-top: calc(var(--bazis-margin-3x) * -3);
    }
}

app-contract-list-single-item {
    .sl-dot {
        padding: 0 var(--bazis-padding-2x);
        color: var(--bazis-text-color-secondary);
    }

    .sl-arrow {
        color: var(--bazis-text-color-secondary);

        + .sl-dot {
            display: none;
        }
    }
}
