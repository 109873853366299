.sl-entrance-page {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    // background: var(--bazis-background-medium);

    .bazis-block {
        --margin-top: var(--bazis-margin-8x);
        --margin-bottom: var(--bazis-margin-8x);
        --margin-start: auto;
        --margin-end: auto;
        --padding-start: var(--bazis-padding-8x);
        --padding-end: var(--bazis-padding-8x);
        --padding-top: 40px;
        --padding-bottom: 40px;
        --width: 394px;
        max-width: 100%;

        &_shadow {
            --box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        }

        @media (max-width: 576px) {
            --margin-top: 0;
            --margin-bottom: 0;
            --padding-start: var(--bazis-padding-6x);
            --padding-end: var(--bazis-padding-6x);
            --width: 100%;
            --border-color: transparent;
            --box-shadow: none;

            h4 {
                text-align: left;
            }

            bazis-button:not(.bazis-control bazis-button) {
                --height: 44px;
            }
        }
    }

    .bazis-menu_rounded {
        .bazis-item {
            --padding-top: calc(var(--bazis-padding-1x)* var(--multiple));
            --padding-bottom: calc(var(--bazis-padding-1x)* var(--multiple));
            --padding-start: calc(var(--bazis-padding-1x)* var(--multiple));
            --padding-end: calc(var(--bazis-padding-1x)* var(--multiple));
            --font-weight: 400;

            cursor: pointer;
            width: 100%;

            &--active,
            &--active bazis-icon {
                background: var(--background-hover);
            }

            &--disabled {
                pointer-events: none;
            }
        }
    }

    .bazis-control {
        margin-bottom: var(--bazis-margin-4x);
    }

    header,
    footer {
        border-style: solid;
        border-color: var(--bazis-border-color);
        text-align: center;
        background: rgba(var(--bazis-background-rgb), 0.2);
        // backdrop-filter: blur(2px);
    }

    header {
        border-width: 0 0 1px 0;

        > div {
            position: relative;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .sl-header__faq {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -10px;
            font-weight: 400;
        }

        .sl-header__back {
            display: block;
            margin: var(--bazis-margin-6x) 0;
            text-decoration: none;
            text-align: left;

            bazis-icon {
                padding: 10px;
                border-radius: 50%;
                background: var(--bazis-background-medium);
                margin-right: var(--bazis-margin-4x);
            }
        }

        @media (max-width: 1024px) {
            bazis-logo {
                --height: 36px;
                --width: 44px;

                &::part(image) {
                    height: 100%;
                }
            }
        }

        @media (max-width: 992px) {
            > div {
                justify-content: space-between;
            }

            .sl-header__faq {
                position: static;
                margin-top: 0;
            }

            + div {
                min-height: calc(100vh - 169px);
            }
        }

        @media (max-width: 576px) {
            border-width: 0;
            padding: 0 var(--bazis-padding-6x);

            + div {
                height: auto;
                min-height: calc(100vh - 204px);
            }
        }
    }

    footer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 112px;
        border-width: 1px 0 0 0;

        .bazis-list {
            justify-content: center;

            &.bazis-list_short-info .bazis-item {
                --font-size: 13px;
                --color: var(--bazis-text-color-secondary);
            }

            a {
                text-decoration: none;
                color: var(--bazis-text-color-secondary);

                &:hover {
                    color: var(--bazis-link-color);
                }
            }
        }

        @media (max-width: 576px) {
            background: var(--bazis-background-medium);
            justify-content: flex-start;
            padding: 0 var(--bazis-padding-6x);
            text-align: left;

            .bazis-list {
                flex-direction: column;
                align-items: flex-start;

                &.bazis-list_short-info .bazis-item {
                    --padding-start: 0;
                    --margin-bottom: var(--bazis-margin-3x);

                    &:last-child {
                        --margin-bottom: 0;
                    }

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    h4 {
        margin-bottom: var(--bazis-margin-3x);
    }

    p:not(.bazis-control p, bazis-display-error p) {
        margin-bottom: var(--bazis-margin-8x);
    }

    bazis-button:not(.bazis-control bazis-button) {
        margin-bottom: var(--bazis-margin-4x);
    }

    h4,
    p,
    bazis-button:not(.bazis-control bazis-button) {
        width: 100%;
    }

    hr {
        margin: var(--bazis-margin-1x) auto var(--bazis-margin-3x);
        background: var(--bazis-border-color);

        &:first-of-type + .bazis-menu_rounded {
            --margin-top-list: var(--bazis-margin-6x);
            --margin-bottom-list: var(--bazis-margin-6x);
        }
    }

    fieldset {
        margin-top: var(--bazis-margin-3x);
        border: 0;
        border-top: 1px solid var(--bazis-border-color);
    }

    legend {
        padding: 0 var(--bazis-padding-2x);
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        color: var(--bazis-text-color-secondary);
    }

    form {
        .sl-forgotten-pass {
            font-weight: 400;
        }
    }

    bazis-sign-status {
        margin: 0 auto var(--bazis-margin-3x);
    }
}

app-entrance-organization .bazis-block {
    --width: 506px;

    p {
        margin-bottom: var(--bazis-margin-5x);
    }
}

.sl-spoofed-page app-entrance {
    height: auto;

    header,
    footer,
    app-entrance-webgl {
        display: none;
    }
}
