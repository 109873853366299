bazis-status {
    .bazis-status {
        cursor: default;
    }

    > div {
        display: inline-block;
        max-width: 100%;
        vertical-align: middle;
    }
}
