.bazis-chat__reply {
    display: flex;
    gap: var(--bazis-margin-4x);
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--bazis-margin-4x);

    &__text {
        width: calc(100% - var(--bazis-margin-4x) - 36px);
    }

    .bazis-chat__title {
        margin-bottom: var(--bazis-margin-1x);
    }

    .bazis-chat__text {
        width: 100%;
        font-size: 13px;
        line-height: 16px;
    }

    .bazis-btn_solid {
        --background: transparent;
        --color: var(--bazis-text-color-secondary);
        --background-hover: var(--bazis-border-color);
        --color-link-hover: var(--color);
    }

    .bazis-figure {
        --width: 40px;
        --height: 40px;
        --background: rgba(var(--bazis-color-action-rgb), 0.5);
        margin: 0;
    }
}
