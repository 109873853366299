.bazis-range-controls {
    display: flex;

    .bazis-control__field-group {
        --border-width: 1px 0 1px 0;
        border-radius: 0;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            height: var(--height);
            width: 1px;
            background: var(--bazis-border-color);
        }
    }

    > :first-child {

        .bazis-control__field-group,
        &.bazis-control__field-group {
            --border-width: 1px 0 1px 1px;
            border-radius: var(--border-radius) 0 0 var(--border-radius);

            &::before {
                display: none;
            }
        }
    }

    > :last-child {

        .bazis-control__field-group,
        &.bazis-control__field-group {
            --border-width: 1px 1px 1px 0;
            border-radius: 0 var(--border-radius) var(--border-radius) 0;
        }
    }
}
