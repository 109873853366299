.bazis-browse-default {
    --margin-bottom: var(--bazis-margin-6x);
    --margin-title: 0 0 var(--bazis-margin-2x);
    --color: var(--bazis-text-color-secondary);
    display: block;

    .bazis-control__field-group_estimated {
        .bazis-control__field-wrapper {
            width: 100%;

            .bazis-control__field span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
