dl {
    &.bazis-dl_inline {
        --width-dt: 216px;
        --width-dd: calc(100% - var(--width-dt));
        --padding-end-dt: var(--bazis-padding-4x);
        --margin-bottom-dt: var(--bazis-margin-4x);
        --margin-bottom-dd: var(--bazis-margin-4x);
        --color-dt: var(--bazis-text-color-secondary);
        line-height: 20px;
        font-size: 13px;

        .bh-flex > & {
            width: 100%;
        }

        .bazis-modal & {
            // точно надо переопределять?
            --margin-bottom-dt: var(--bazis-margin-4x);
            --margin-bottom-dd: var(--bazis-margin-4x);
        }

        dd {
            .bazis-list_between > .bazis-item {
                --margin-bottom-dd: 0;
            }
        }

        dt,
        dd {
            &:last-of-type {
                // --margin-bottom-dt: 0;
                // --margin-bottom-dd: 0;
                margin: 0;
            }
        }
    }

    &.bazis-dl_dot-leaders {
        --width-dt: 280px;
        --width-dd: calc(100% - var(--width-dt));
        --padding-start-dd: var(--bazis-padding-6x);
        --margin-bottom-dt: var(--bazis-margin-4x);
        --margin-bottom-dd: var(--bazis-margin-4x);
        --color-dt: var(--bazis-text-color-secondary);
        --color-dot: var(--bazis-text-color-secondary);
        --margin-bottom-dl: 0;

        dt:last-of-type,
        dd:last-of-type {
            --margin-bottom-dt: 0;
            --margin-bottom-dd: 0;
        }

        .bazis-list_short-info {
            --color: var(--bazis-text-color);

            bazis-link {
                font-weight: 400;
            }
        }

        .bazis-block_accent & {
            --color-dt: rgba(var(--bazis-text-color-rgb), 0.6);
        }
    }

    &.bazis-dl_easy {
        --margin-bottom-dt: var(--bazis-margin-1x);
        --margin-bottom-dd: var(--bazis-margin-3x);
        --color-dt: var(--bazis-text-color-secondary);
        margin: 0;
        line-height: 20px;

        dt {
            font-size: 11px;
            line-height: 12px;
        }
    }

    @media (max-width: 576px) {
        &.bazis-dl_inline {
            --margin-bottom-dt: 0;

            dt,
            dd {
                float: none;
                clear: none;
                width: 100%;
            }

            dd:last-child {
                margin-bottom: 0;
            }
        }
    }
}
