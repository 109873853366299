.sl-announcement-item {
    display: block;
    position: relative;
    min-height: 100%;
    border: 1px solid var(--bazis-border-color);
    padding-bottom: calc(var(--bazis-padding-4x) * 2 + var(--bazis-line-height-p-minor) + 1px);
    border-radius: 12px;
    cursor: pointer;

    &__image {
        --height: 160px;
        --width: calc(100% + 2px);
        --border-radius: 12px 12px 0 0;
        // --background: var(--bazis-color-info);
        margin: -1px -1px 0;
    }

    &__title {
        font-weight: 500;
        margin: var(--bazis-padding-4x);
    }

    &__date {
        position: absolute;
        bottom: 0;
        margin: 0;
        left: var(--bazis-padding-4x);
        right: var(--bazis-padding-4x);
        display: flex;
        justify-content: space-between;
        padding: var(--bazis-padding-4x) 0;
        border-top: 1px solid var(--bazis-border-color);

        bazis-link {
            display: none;
        }
    }

    &:hover &__date {
        bazis-link {
            display: block;
        }
    }

    &:hover {
        box-shadow: 0px 10px 20px -10px #9096a226;
    }
}

.sl-announcement {
    max-width: 896px;
    margin-bottom: 120px;

    &__date {
        margin: 0 0 var(--bazis-margin-5x);

        @media screen and (max-width: 1024px) {
            margin: var(--bazis-margin-8x) 0 var(--bazis-margin-5x);
        }
    }

    h1 {
        --bazis-h1-margin: 0 0 var(--bazis-margin-3x);
    }

    &__image {
        --height: 360px;
        --width: 100%;
        --border-radius: 12px;
        --background: var(--bazis-color-info);
        margin: 0;
        margin-top: var(--bazis-margin-8x);

        @media screen and (max-width: 576px) {
            --height: 160px;
        }
    }

    &__body {
        margin-top: calc(var(--bazis-margin-3x) * 3);

        p {
            --bazis-font-size-p: 18px;
            --bazis-line-height-p: 28px;
            --bazis-p-margin: var(--bazis-multiple-px) 0 var(--bazis-margin-7x);
        }

        li {
            font-size: 18px;
            line-height: 28px;
            list-style-position: outside;
            margin-bottom: var(--bazis-margin-7x);
        }

        ol,
        ul {
            padding: 0 0 0 var(--bazis-padding-5x);
            margin: 0;
        }

        ul {
            li::marker {
                color: var(--bazis-color-primary);
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            strong,
            b {
                font-weight: inherit;
            }
        }

        table {
            position: relative;
            border: none;

            p {
                --bazis-font-size-p: 13px;
                --bazis-line-height-p: 20px;
                --bazis-p-margin: var(--bazis-multiple-px) 0 var(--bazis-margin-2x);

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }

            li {
                font-size: 13px;
                line-height: 20px;
                margin-bottom: var(--bazis-margin-2x);

                &:last-child {
                    margin-bottom: 0;
                }
            }

            td {
                --padding-top: var(--bazis-padding-3x);
                --padding-bottom: var(--bazis-padding-3x);
                --padding-start: var(--bazis-padding-3x);
                --padding-end: var(--bazis-padding-3x);
            }

            th:last-child {
                text-align: left;
            }

            tr:first-child td,
            thead th,
            tbody th {
                --padding-start: var(--bazis-padding-2x);
                --padding-top: var(--bazis-padding-2x);
                --padding-end: var(--bazis-padding-2x);
                --padding-bottom: var(--bazis-padding-2x);
                --font-size: 13px;
                --font-weight: 500;
                --line-height: 20px;

                --background: var(--bazis-background-medium);
                --color: var(--bazis-text-color-secondary);

                b, strong {
                    font-weight: 500;
                }
                i, em {
                    font-style: normal;
                }
            }

            tr:first-child {
                position: sticky;
                top: 0;
            }
        }
    }

    &__similar {
        margin-top: 120px;

        h2 {
            padding-right: 116px;
        }

        .bazis-slider {
            position: relative;

            &__content {
                gap: var(--bazis-margin-6x);
            }

            .bazis-block {
                --padding-top: 0;
                --padding-bottom: 0;
                --padding-start: 0;
                --padding-end: 0;
                --margin-bottom: 20px;
                --width: calc((100% - var(--bazis-margin-6x) * 2) / 3);

                &:hover {
                    box-shadow: 0px 10px 20px -10px #9096a226;
                }
            }

            &__controls {
                position: absolute;
                right: 0;
                top: -82px;
                margin: 0;
            }
        }

        @media (max-width: 1024px) {
            .bazis-slider {
                .bazis-block {
                    --width: calc((100% - var(--bazis-margin-6x)) / 2);
                }
            }
        }
        @media (max-width: 576px) {
            .bazis-slider {
                .bazis-block {
                    --width: 100%;
                }
            }
        }
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: var(--bazis-margin-4x);
        padding-top: var(--bazis-padding-6x);
        border-top: 1px solid var(--bazis-border-color);
    }

    @media screen and (max-width: 1200px) {
        max-width: 736px;
    }

    @media screen and (max-width: 768px) {
        max-width: 100%;
    }
}
