@import "./src/bazis/styles/bazis";

@import './src/bazis/styles/themes/sw/core.scss';

@import "typography";


/* Layout Components of alphabet */
@import 'layout/aside';
@import 'layout/footer';
@import 'layout/header';
@import 'layout/layout';
@import 'layout/section';

/* Bazis components and classes, extended */
@import 'base/main.scss';
@import 'components/web/main.scss';
@import 'components/common/main.scss';
@import 'components/form/main.scss';
@import 'components/map/map';
@import "components/table/main.scss";

@import 'components/chat/chat';

/* Project Components of alphabet */
@import 'components/project/main';

/* Filters Components */
@import 'components/filter/filter';
@import 'components/filter/control.base';
@import 'components/filter/control.additional';


/* Pages */
@import 'pages/page';
@import 'pages/dummy-page';
@import 'pages/entrance-page';
@import 'pages/legal-page';
@import 'pages/list-page';
@import 'pages/main-page';
@import 'pages/profile-page';
@import 'pages/service-page';
@import 'pages/simple-page';
@import 'pages/summary-page';


/* Blocks */
@import 'blocks/announcement';
@import 'blocks/faq';
@import 'blocks/notifications';
@import 'blocks/propositions';
@import 'blocks/summary';
@import 'blocks/other';
