.bazis-list-page {
    &__head {
    }

    &__tools {
        margin-bottom: var(--bazis-margin-4x);

        &.bh-no-margin {
            margin-bottom: 0;
        }
    }


    @media (max-width: 1024px) {
        &__title {
            justify-content: space-between;
        }
        &__filters--collapsed {
            overflow: hidden;
        }
    }

    @media (max-width: 576px) {
        .bazis-list-page__body {
            .bazis-block_shadow {
                .sl-accordion_info {
                    table,
                    tbody,
                    tr,
                    td {
                        display: block;
                    }

                    td.bh-text-right {
                        --padding-start: 0;
                        text-align: left !important;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 500;
                    }

                    tr:not(:last-child) {
                        margin-bottom: var(--bazis-margin-6x);
                    }
                }
            }
        }

        & &__title {
            display: flex;

            & > .bh-flex {
                display: block;
            }
        }
    }
}
